import React from 'react';
import PastasList from './PastasList';
import axios from 'axios';
import ApiEndpoints from './ApiEndpoints';
import Loader from './Loader';
import {Helmet} from "react-helmet";

export default class PastasWithTag extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('searchTag') + this.props.match.params.tagText)
            .then((response) => {
                self.setState({
                    pastas: response.data,
                    launchLoader: false
                });
            });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.tagText !== this.props.match.params.tagText) {

            let self = this;

            this.setState({
                launchLoader: true
            });

            axios.get(ApiEndpoints.getUrl('searchTag') + nextProps.match.params.tagText)
                .then((response) => {
                    self.setState({
                        pastas: response.data,
                        launchLoader: false
                    });
                });
        }

    }

    render() {

        let {tagText} = this.props.match.params;

        let metaTags = (
            <Helmet>
                <title>Pasty z tagiem #{tagText} - Copypasta.pl</title>
                <meta name="description"
                      content={`Zbiór wszystkich pasty z tagiem ${tagText} w serwisie Copypasta.pl`}/>
                <meta name="keywords" content={`pasty tag ${tagText} Copypasta.pl `}/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content={`Pasty z tagiem ${tagText} Copypasta.pl`}/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description"
                      content={`Zbiór wszystkich pasty z tagiem ${tagText} w serwisie Copypasta.pl`}/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={`Pasty z tagiem ${tagText} - Copypasta.pl`}/>
                <meta name="twitter:description"
                      content={`Zbiór wszystkich pasty z tagiem ${tagText} w serwisie Copypasta.pl`}/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    Pasty z tagiem <strong>#{tagText}</strong>
                    <PastasList
                        canAddToFav={true}
                        canRemoveFromFav={false}
                        canHide={false}
                        canDelete={false}
                        canEdit={false}
                        listOfPastas={this.state.pastas}/>
                </div>
            </div>

        )
    }
}