import React from 'react'
import './loader.css'
import PropTypes from 'prop-types';

class Loader extends React.Component {
    render() {

        let loaderClass = 'loader hidden';

        if (this.props.launchLoader) {
            loaderClass = 'loader';
        }

        return (
            <div className={loaderClass}>
                <div className='loader_overlay disableOnDesktop'></div>
                <div className='loader_cogs'>
                    <div className='loader_cogs__top'>
                        <div className='top_part'></div>
                        <div className='top_part'></div>
                        <div className='top_part'></div>
                        <div className='top_hole'></div>
                    </div>
                    <div className='loader_cogs__left'>
                        <div className='left_part'></div>
                        <div className='left_part'></div>
                        <div className='left_part'></div>
                        <div className='left_hole'></div>
                    </div>
                    <div className='loader_cogs__bottom'>
                        <div className='bottom_part'></div>
                        <div className='bottom_part'></div>
                        <div className='bottom_part'></div>
                        <div className='bottom_hole'></div>
                    </div>
                </div>
            </div>
        )
    }
}

Loader.propTypes = {
    launchLoader: PropTypes.bool.isRequired
};

export default Loader;