let newState = {
    redirected_to_pasta: false,
};

let userReducer = (state = newState, action) => {
    switch (action.type) {

        case 'USER_LOGGED_IN':
            let newState = {
                ...state,
                user: action.payload.user,
                timeSet: new Date().getTime().toString()
            };
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;

        case 'USER_FB_TOKEN_RECEIVED':
            return {
                ...state, credentials: action.payload, timeSet: new Date().getTime().toString()
            };
        case 'STATE_RESTORE':
            let oldStateJson = localStorage.getItem('state');
            if (oldStateJson && Object.keys(state).length === 0) {
                let oldState = JSON.parse(oldStateJson);
                if (oldState && (parseInt(new Date().getTime().toString(), 10) - parseInt(oldState.timeSet, 10) < 2000000)) {
                    return oldState;
                } else {
                    localStorage.clear();
                }
            }
            return state;
        case 'REDIRECT_PASTA':
            return {
                ...state, redirected_to_pasta: action.payload,
            };

        case 'USER_LOG_OUT':
            localStorage.removeItem('state');
            return {
                ...state,
                user: null,
                timeSet: null,
            };
        default:
            return state;
    }
};
export default userReducer;