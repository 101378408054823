
export function copyToClipboard(textId)
{
    let item = document.getElementById(textId);
    let selection;
    let range;

    let result = true;

    if (document.body.createTextRange) { // ms
        range = document.body.createTextRange();
        range.moveToElementText(item);
        range.select();
    }
    else if (window.getSelection()) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(item);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    else {
        return false;
    }
    try {

        // The important part (copy selected text)
        let ok = document.execCommand('copy');

       if(!ok) result = false;

    } catch (err) {
        result = false;
    }

    if (window.getSelection()) selection.removeAllRanges();
    return result;
}

