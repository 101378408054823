import React from 'react'
import {Route, Switch} from 'react-router'
import {render} from 'react-dom';
import Fresh from './Fresh'
import Home from './Home'
import Gold from './Gold'
import Account from './Account'
import './app.css'
import 'bootstrap/dist/css/bootstrap.css'
import './index.scss'
import './whhg.css'
import PastasWithTag from './PastasWithTag'
import User from './User'
import AddPasta from './AddPasta'
import PastaDetails from './PastaDetails'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import Reducers from './Reducers'
import ApiEndpoints from "./ApiEndpoints";
import {FacebookProvider} from 'react-facebook';
import 'react-bootstrap/dist/react-bootstrap'
import FavouritePastas from './FavouritePastas'
import RecentPastas from './RecentPastas'
import UserPastas from './UserPastas'
import Regulations from './Regulations'
import ContactWithUs from './ContactWithUs'
import Advert from './Advert'
import FullSearch from './FullSearch'
import {BrowserRouter, Link} from "react-router-dom";
import Nav from "./Navbar";
import {links} from './utils/constants';

let store = createStore(Reducers);
render((
    <FacebookProvider appId={ApiEndpoints.fbAppId}>
        <Provider store={store}>
            <div className='outer-container'>
                <BrowserRouter basename='/'>
                    <Nav tabs={links}/>
                    <div id="page-wrap">
                        <Switch>
                            <Route path='/' exact component={Home}/>
                            <Route path='/fresh/:page?' component={Fresh}/>
                            <Route path='/gold' component={Gold}/>
                            <Route path='/add' component={AddPasta}/>
                            <Route path='/tag/:tagText' component={PastasWithTag}/>

                            <Route path='/regulations' component={Regulations}/>
                            <Route path='/contact' component={ContactWithUs}/>
                            <Route path='/advert' component={Advert}/>
                            <Route path='/search/:query' component={FullSearch}/>

                            <Route path='/pasta/:pastaId/:pastaSlug?' component={PastaDetails}/>

                            <Route path='/user/pastas/edit/:pastaId' component={AddPasta}/>
                            <Route path='/user/favourite' component={FavouritePastas}/>
                            <Route path='/user/pastas' component={UserPastas}/>
                            <Route path='/user/recent' component={RecentPastas}/>
                            <Route path='/user/:userId' component={User}/>
                            <Route path='/user' component={Account}/>
                        </Switch>
                    </div>
                    <span className="infoBottom fixedBottom disableOnMobile">
                        <span className="link_underline link_color"><Link to='/contact'> Kontakt </Link></span>
                        <span className="link_underline link_color"><Link to='/regulations'> Regulamin </Link></span>
                        <span className="link_underline link_color"><Link to='/advert'> Reklama </Link></span>
                        <span className="link_underline link_color"><a href="http://www.hanuszki.pl"> Hanuszki </a></span>
                </span>

                </BrowserRouter>
            </div>
        </Provider>
    </FacebookProvider>

), document.getElementById('root'));
