import React from 'react'

class UsersColors extends React.Component {
    render() {

        let color = '';

        switch(this.props.role)
        {
            case 'mode':
                color = 'greenMode';
                break;
            case 'root':
                color = 'redRoot';
                break;
            default:
                color = 'greyNorm';
        }

        return (
            <span className={color}>{this.props.userName}</span>
        )
    }
}
export default UsersColors;