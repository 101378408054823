import React, {PureComponent} from 'react'
import Tag from './Tag'

export default class Tags extends PureComponent {

    render() {
        let tags = [];
        if (typeof this.props.taglist !== 'undefined') {
            tags = this.props.taglist.map(tagT => <Tag key={tagT.id} valueTag={tagT.text}/>);
        }

        return (
            <div className="pasta_tags">
                {tags}
            </div>
        )
    }
}