import React from 'react'
import {string} from 'prop-types';

const IncludeIcon = ({iconBase, iconDetails, ...props}) => {
    return (
        <span className={`icon ${iconBase} ${iconDetails}`} {...props}/>
    )
};

IncludeIcon.propTypes = {
    iconBase: string.isRequired,
    iconDetails: string.isRequired
};

export default IncludeIcon;