import React from 'react'
import ApiEndpoints from './ApiEndpoints'
import PopUpUserAccount from './PopUpUserAccount'
import axios from 'axios'
import Loader from './Loader'
import PropTypes from 'prop-types'

class TriggerPopUpUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, props,
            {
                userInfo: '',
                launchLoader: false
            }
        );
    }

    showUser(event) {
        event.preventDefault();
        let self = this;

        this.setState({launchLoader: true});

        axios.get(ApiEndpoints.getUrl('userInfo') + this.props.userId)
            .then((response) => {
                self.setState({
                    userInfo: response.data,
                    showUserPopup: true,
                    launchLoader: false
                });
            })
            .catch(() => {
                self.setState({
                    launchLoader: false
                })
            });
    }

    hideUser(event) {
        event.preventDefault();
        this.setState({
            userInfo: '',
            showUserPopup: false
        });
    }

    render() {
        return (
            <span>
                <Loader launchLoader={this.state.launchLoader}/>
                <span onMouseLeave={this.hideUser.bind(this)}>
                    <PopUpUserAccount userRole={this.props.userRole} showUserPopup={this.state.showUserPopup}
                                      userInfo={this.state.userInfo}/>
                </span>
                <span onMouseEnter={this.showUser.bind(this)}>
                    {this.props.children}
                </span>

            </span>

        )
    }
}

TriggerPopUpUser.propTypes = {
    userId: PropTypes.string.isRequired
};

TriggerPopUpUser.defaultProps = {
    showPopUpUser: false,
    userRole: ''
};

export default TriggerPopUpUser;