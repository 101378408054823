export const links = [
    {
        name: "Hot",
        route: "/",
        auth: false,
        hasIcon: true,
        icon: "hot"
    },
    {
        name: "Świeże",
        route: "/fresh",
        auth: false,
        hasIcon: true,
        icon: "soup"
    },
    {
        name: "Gold",
        route: "/gold",
        auth: false,
        hasIcon: true,
        icon: "value-coins"
    },
    {
        name: "Dodaj",
        route: "/add",
        auth: true,
        hasIcon: true,
        icon: "circleadd"
    }
];