import React from 'react';
import Loader from './Loader';
import axios from 'axios';
import ApiEndpoints from './ApiEndpoints';
import Utils from './Utils';
import PastasList from './PastasList';
import {Helmet} from "react-helmet";

class FullSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            launchLoader: false,
            pastasList: []
        }
    }

    componentDidMount() {

        let searchUtil = Utils.serializeToUri({
            text: this.props.match.params.query
        });
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('searchContent') + searchUtil)
            .then((response) => {
                self.setState({
                    pastasList: response.data,
                    launchLoader: false
                });
            })
            .catch(() => {
                self.setState({
                    launchLoader: false
                });
            });
    }

    componentWillReceiveProps(nextProps) {
        let searchUtil = Utils.serializeToUri({
            text: nextProps.match.params.query
        });
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('searchContent') + searchUtil)
            .then((response) => {
                self.setState({
                    pastasList: response.data,
                    launchLoader: false
                });
            })
            .catch(() => {
                self.setState({
                    launchLoader: false
                });
            });
    }

    render() {

        let metaTags = (
            <Helmet>
                <title>Zaawansowana wyszukiwarka - Copypasta.pl</title>
                <meta name="description"
                      content='Tutaj możesz wyszukać pastę wpisując fragment danej pasty, tag czy tytuł'/>
                <meta name="keywords" content='wyszukiwarka zaawansowana pasty tag tytuł fragment Copypasta.pl '/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Zaawansowana wyszukiwarka - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description"
                      content='Tutaj możesz wyszukać pastę wpisując fragment danej pasty, tag czy tytuł'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='>Zaawansowana wyszukiwarka - Copypasta.pl'/>
                <meta name="twitter:description"
                      content='Tutaj możesz wyszukać pastę wpisując fragment danej pasty, tag czy tytuł'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        let noResult = null;

        if (this.state.pastasList.length === 0) {
            noResult = <div>
                Brak wyników dla: <strong>{this.props.match.params.query}</strong>
            </div>;
        }

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>

                {metaTags}
                <div className="marginTop">
                    {noResult}
                    <PastasList listOfPastas={this.state.pastasList}
                                isMoreButtonClicked={true}/>
                </div>
            </div>
        )
    }
}

export default FullSearch;