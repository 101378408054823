import React from 'react'
import ApiEndpoints from './ApiEndpoints'
import StatsComponent from './StatsComponent'
import axios from 'axios'
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import Utils from './Utils';
import IncludeIcon from './IncludeIcon'
import UsersColors from './UsersColors'

class PopUpUserAccount extends React.Component {

    giveModeClick() {
        let self = this;
        let obj = Object.assign({
            id: this.props.userInfo.user.id,
        }, self.props.credentials);
        axios.get(ApiEndpoints.getUrl('giveMode') + Utils.serializeToUri(obj))
            .then((response) => {
                alert(response.data);
            });
    }

    render() {

        let showPopupUser;
        let giveMode = null;

        let avatar = null;
        let profile_stats_className = 'profile_stats_with_left_border';

        if(this.props.showUserPopup) {
            if (this.props.user.role === 'root') {
                giveMode = (<div>
                    <button className="btn btn-success" onClick={this.giveModeClick.bind(this)}>
                        <IncludeIcon iconBase="icon" iconDetails="icon-deathstar" /> GIVE MODE
                    </button>
                </div>);
            }

            if(this.props.userInfo.user.has_avatar !== 0)
            {
                avatar = (
                    <img className='imgUserProfile'
                         src={ApiEndpoints.apiOrigin + ApiEndpoints.getUserPhoto + this.props.userInfo.user.id + '.jpg'}
                         alt="awatar uzytkownika"/>
                );

                profile_stats_className = 'profile_stats';
            }

            showPopupUser = (
                <div className="absolute popUp">
                    <div className="fullWidth userProfile">
                        {avatar}
                        <div className={profile_stats_className}>
                            <div className="profile_userName"><UsersColors role={this.props.userInfo.user.role}
                                                                           userName={this.props.userInfo.user.name} />
                            </div>
                            <div className="profile_score">
                                <StatsComponent score={this.props.userInfo.score}
                                                views={this.props.userInfo.views}/>
                                <div className="fullWidth display_block">
                                    <span className="btn link_color link_underline noBg noBoxShadowHover">
                                        <Link to={'/user/' + this.props.userInfo.user.id}>
                                            Pasty użytkownika
                                        </Link>
                                    </span>
                                </div>

                                {giveMode}
                            </div>
                        </div>
                    </div>

                </div>
            );
        }

        return (
            <span>{showPopupUser}</span>
        )
    }
}

PopUpUserAccount.defaultProps ={
    showUserPopup: false,
    user: '',
    userRole: ''
};

const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        user: state.user
    };
};

export default connect(mapStateToProps) (PopUpUserAccount);