import React, { Component } from 'react';
import PastaPill from "./PastaPill";

class SimilarPastas extends Component {

    render() {

        let emptyPastas = null;

        if(!this.props.listOfPastasPills || this.props.listOfPastasPills.length === 0)
        {
            emptyPastas = <div className="empty_similar fullWidth">
                <span style={{width: '100%'}} className="icon-restaurantmenu text-center"/>
            </div>;
        }

        return (
            <div className="display_flex flex-column ">
                {/*Similar Pastas*/}
                {this.props.listOfPastasPills &&
                <div className="display_block text-center top_separator light-grey text-color margin-bottom similar_pastas">
                    Zobacz także
                </div>}
                <div className="display_flex flex-row flex-column-mobile">
                {emptyPastas}
                {this.props.listOfPastasPills && this.props.listOfPastasPills.map((item, i) =>
                <div key={i} className="half_width display_block">
                    <PastaPill key={i} title={item.title} shortContent={item.short_content} author={item.author} id={item.id}
                               has_cover={item.has_cover} author_id={item.author_id} role={item.role}
                               has_avatar={item.has_avatar} />
                </div>
                )}
                </div>

            </div>
        );
    }
}

export default SimilarPastas;