import React from 'react'

class PicRelPreview extends React.Component {
    render() {

        let picrel = null;

        if( this.props.previewPicrelUrl !== '')
        {
            picrel = (
                <div className="boxCentered">
                    <img className="maxWidthPicRel fullWidth"
                         src={this.props.previewPicrelUrl}
                         alt='picrel-preview'/>
                </div>
            );
        }

        return (
            <span>
                {picrel}
            </span>
        )
    }
}

PicRelPreview.defaultProps = {

    previewPicrelUrl: ''
};

export default PicRelPreview;