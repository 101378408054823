import React from 'react'


export default class Regulations extends React.Component {

     render() {
        return (
            <div className="container">
                <div className="marginTop">
                    <span className="pasta_content">
                        <h1>Regulamin</h1>

                            Regulamin serwisu copypasta.pl
                        <br />
 Postanowienia ogólne.
                        <br />
Użyte w Regulaminie pojęcia oznaczają:
                        <br />
Właściciel – osoba posiadająca prawo własności do domeny i hostingu portalu copypasta.pl.
                        <br />
Formularz kontaktowy – dokument elektroniczny, znajdujący się w Serwisie, służący do nawiązywania kontaktów z Właścicielem.
                        <br />
Formularz rejestracyjny - dokument elektroniczny, znajdujący się w Serwisie, służący do rejestracji konta Użytkownika w Serwisie.
                        <br />
Konto – dokument elektroniczny, który powstaje na skutek rejestracji Użytkownika w Serwisie. W ramach Konta Użytkownik może swój Nick.
                        <br />
Regulamin – niniejszy dokument, określający warunki korzystania z Serwisu oraz prawa i obowiązki Użytkowników oraz Właściciela.
                        <br />
Serwis – portal copypasta, dostępny pod adresem sieci Internet https://www.copypasta.pl.
                        <br />
Umowa – umowa o świadczenie usług drogą elektroniczną, dostępnych za pośrednictwem Serwisu, zawarta pomiędzy Właścicielem a Użytkownikiem.
                        <br />
Usługi – usługi świadczone drogą elektroniczną przez Właściciela, za pośrednictwem Serwisu,
                        <br />
Użytkownik – oznacza pełnoletnią osobę fizyczną, posiadającą pełną zdolność do czynności prawnych, działającą we własnym imieniu (użytkownik indywidualny) lub w imieniu osoby prawnej (użytkownik instytucjonalny), która korzysta z Serwisu zgodnie z postanowieniami Regulaminu. Użytkownikiem może być również osoba małoletnia i/lub nieposiadająca pełnej zdolności do czynności prawnych, pod warunkiem uzyskania zgody przedstawiciela ustawowego.
                        <br />
Na podstawie art. 8 ust. 1 pkt. 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. nr 144, poz. 1204 z późn. zm.) Właściciel ustala niniejszym Regulamin.
                        <br />
Rodzaje i zakres usług świadczonych drogą elektroniczną
                        <br />
Usługi dostarczane przez Serwis mogą być płatne i odpłatne. Przed skorzystaniem z dowolnej płatnej usługi Użytkownik jest każdorazowo informowany, by zakup był świadomym wyborem konsumenckim. Właściciel ma prawo do zmiany cen usług i aktualna cena jest widoczna dla Użytkownika przed zakupem.
                        <br />
Usługi dostarczane przez Serwis:
                        <br />
Przechowywanie i wyświetlanie treści publikowanych przez Użytkowników serwisu (tekstów, zdjęć, grafik oraz linków do innych materiałów w sieci Internet)
                        <br />
Tworzenie konta Użytkownika, które pozwala na zapisywanie historii przeglądanych treści oraz wyróżnianie treści, która jest szczególnie przez Użytkownika lubiana
                        <br />
Komentowanie treści
                        <br />
Ocenianie treści
                        <br />
Udostępnianie treści na innych Serwisach internetowych
                        <br />
inne
                        <br />
Warunki świadczenia usług drogą elektroniczną
                        <br />
Aby korzystać z Serwisu konieczne jest spełnienie następujących wymagań technicznych:
                        <br />
Komputer z dostępem do globalnej sieci Internet
                        <br />
Przeglądarka internetowa (zalecane: Mozilla Firefox) w najnowszej wersji opublikowanej przez producenta. Przeglądarka internetowa musi mieć włączoną funkcję interpretowania i wykonywania kodu napisanego w języku programowania javascript, językach deklaratywnych html i css oraz przetwarzania danych w postaci JSON. Dodatkowo wymagana jest obsługa cookies w przeglądarce.
                        <br />
Konto w serwisie facebook.com oraz zaakceptowanie dostępu aplikacji copypasta do profilu, z którego korzysta Użytkownik podczas rejestrowania się w Serwisie
                        <br />
Użytkownik przed rozpoczęciem korzystania z dowolnej z usług Serwisu ma obowiązek zapoznania się i akceptacji regulaminu. Korzystanie ze strony oznacza akceptację Regulaminu i zawartych w nim postanowień. Publikowane przez Użytkownika treści muszą być zgodne z polskim prawem i po stronie Użytkownika leży odpowiedzialność za publikowane treści. Użytkownik ponosi pełną odpowiedzialność za korzystanie z serwisu wbrew postanowieniom zawartym w Regulaminie.
                        <br />
Publikując dowolne treści w Serwisie Użytkownik oznajmia, że posiada wszelkie prawa pozwalające na publikację treści oraz publikowane treści są zgodne z polskim prawem.
                        <br />
Użytkownik udziela Właścicielowi oraz innym Użytkownikom na czas nieokreślony niewyłącznej, nieodpłatnej licencji na rozpowszechnianie, kopiowanie i utrwalenie materiałów zamieszczanych przez Użytkownika w Serwisie w dowolny sposób, na wszystkich polach eksploatacji, również do celów reklamowych jak i promocyjnych.
                        <br />
Zakazane jest publikowanie wszelkich materiałów, które mogą zawierać złośliwy kod, który mógłby zostać wykonany przez przeglądarki internetowe innych Użytkowników działając na ich szkodę.
                        <br />
Publikowane przez Użytkowników treści nie są w jakikolwiek kojarzone z osobą Właściciela i dowolnych współpracowników Serwisu, nie stanowią manifestu poglądów czy wierzeń Właściciela.
                        <br />
Użytkownik, aby stworzyć konto w Serwisie musi zaakceptować regulamin usługi facebook.com. W celu autentyfikacji Użytkownika Serwis przechowuje unikalny identyfikator dostarczony przez usługę facebook.com.
                        <br />
Dane przechowywane przez Serwis dotyczące Użytkownika nie pozwalają określić osoby fizycznej przy użyciu niskich kosztów, małej ilości czasu, więc należy uznać, że Serwis nie przechowuje danych osobowych.
                        <br />
Gdy zdaniem Właściciela, treść publikowana przez Użytkownika nie powinna znajdować się w Serwisie, Właściciel zastrzega sobie prawo do ukrywania, usuwania, modyfikacji treści bez podania przyczyny w każdej chwili. Dodatkowo, możliwe jest zablokowanie dostępu Użytkownika do konta w Serwisie, bez podania przyczyny. Blokada konta w szczególności może być skutkiem publikacji treści, które nie powinny się znaleźć w Serwisie.
                        <br />
W Serwisie używane są reklamy zewnętrznych dostawców, np. Google AdSense. Wspomniani dostawcy mogą używać plików cookies do analizy ruchów Użytkownika na stronie w celu dopasowania reklam.
                        <br />
Reklamacje dotyczące Serwisu oraz działalności Właściciela, Użytkownik może zgłaszać za pomocą usługi facebook.com. Link do wskazanej metody kontaktu jest dostępny pod adresem www.copypasta.pl/#/contact. Reklamacja zawierać musi imię, nazwisko, email Reklamującego oraz szczegółowy opis problemu, linki do treści które mogą zdaniem Reklamującego łamać regulamin oraz uzasadnienie do każdej poruszanej w reklamacji kwestii. Właściciel rozpatrzy tylko reklamacje nadesłane w poprawnej formie w terminie 14 dni roboczych. W przypadku potrzeby dłuższego czasu na analizę reklamacji, Właściciel powiadamia Reklamującego o przyczynach opóźnienia mailowo pod podany w reklamacji adres email.
                        <br />
Niniejszy Regulamin obowiązuje od 1.03.2017 r i jest dostępny pod adresem www.copypasta.pl/#/regulations. Właściciel zastrzega sobie prawo do dowolnej zmiany w regulaminie bez zapowiedzi, w każdej chwili bez podania przyczyny. Zmiany wchodzą w życie wraz z ich publikacją.
                        <br />
Prawem właściwym jest prawo polskie.

                    </span>
                </div>
            </div>

        )
    }
}