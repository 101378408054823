import React from 'react'
import PastasList from './PastasList'
import {connect} from 'react-redux'
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from './Loader'
import AndroidAppLink from "./AndroidAppLink";
import {Helmet} from "react-helmet";

class Fresh extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            hasMoreItems: false,
            whichPage: 1,
            canDelete: false,
            canEdit: false,
            canHide: false,
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrlForPage('pastasFresh', this.props.match.params.page))
            .then(function (response) {
                let pastas = response.data.map((pasta) => ({...pasta, confirmed: false}));

                if (typeof self.props.user !== 'undefined') {
                    if (self.props.user.role === 'mode' || self.props.user.role === 'root') {
                        self.setState({
                            pastas: pastas,
                            canDelete: true,
                            canEdit: true,
                            canHide: true,
                            launchLoader: false
                        });
                    } else {
                        self.setState({
                            pastas: pastas,
                            launchLoader: false
                        });
                    }
                } else {
                    self.setState({
                        pastas: pastas,
                        launchLoader: false
                    });
                }

                axios.get(ApiEndpoints.getUrlForPage('hasMorePastasFresh', self.state.whichPage))
                    .then(function (response) {
                        if (response.data) {
                            self.setState({
                                whichPage: self.state.whichPage + 1,
                                hasMoreItems: true,
                                launchLoader: false
                            })
                        } else {
                            self.setState({
                                hasMoreItems: false,
                                launchLoader: false
                            })
                        }
                    })
                    .catch(function () {
                        self.setState({
                            hasMoreItems: false,
                            launchLoader: false
                        });
                    })
            })
            .catch(() => {
                self.setState({
                    launchLoader: false
                })
            });
    }

    loadMoreContent() {
        let self = this;

        axios.get(ApiEndpoints.getUrlForPage('pastasFresh', this.state.whichPage))
            .then(function (response) {
                let pastas = response.data.map((pasta) => ({...pasta, confirmed: false}));
                self.setState({pastas: self.state.pastas.concat(pastas)});
                axios.get(ApiEndpoints.getUrlForPage('hasMorePastasFresh', self.state.whichPage))
                    .then(function (response) {
                        if (response.data) {
                            self.setState({
                                whichPage: self.state.whichPage + 1,
                                hasMoreItems: true
                            })
                        } else {
                            self.setState({
                                hasMoreItems: false
                            })
                        }
                    })
                    .catch(function () {
                        self.setState({
                            hasMoreItems: false
                        });
                    })
            })
    }

    render() {

        let metaTags = (
            <Helmet>
                <title>Świeże pasty - Copypasta.pl</title>
                <meta name="description" content='Najnowsze pasty specjalnie dla Ciebie'/>
                <meta name="keywords" content='pasty nowe świeże Copypasta.pl '/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Świeże pasty - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description" content='Najnowsze pasty specjalnie dla Ciebie'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='Świeże pasty - Copypasta.pl'/>
                <meta name="twitter:description" content='Najnowsze pasty specjalnie dla Ciebie'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    <AndroidAppLink/>
                    <InfiniteScroll initialLoad={true} threshold={300} loadMore={this.loadMoreContent.bind(this)}
                                    hasMore={this.state.hasMoreItems}>
                        <PastasList canDelete={this.state.canDelete} canEdit={this.state.canEdit}
                                    canHide={this.state.canHide} listOfPastas={this.state.pastas}
                                    canRemoveFromFav={false} canAddToFav={true}/>
                    </InfiniteScroll>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        credentials: state.credentials
    };
};
export default connect(mapStateToProps)(Fresh);