import React from 'react'
import axios from 'axios'
import ApiEndpoints from '../ApiEndpoints'
import {connect} from "react-redux";
import Utils from '../Utils';
import Alert from '../Alert'
import Dropzone from 'react-dropzone'
import Pasta from '../Pasta'
import Loader from '../Loader'
import {Button, Form, Input, Select} from 'antd';
import './add-pasta-form.scss';

const {TextArea} = Input;

class PastaForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                cover: "",
                coverToSend: '',
                toSendPicRel: '',

            },
            isAlertTriggered: false,
            alertClass: '',
            alertText: '',
            hasUploadedPhoto: false,
            launchLoader: false
        };
    }

    onDropCover(files) {
        let formN = this.state.form;
        formN.cover = files[0].preview;
        formN.coverToSend = files[0];

        this.setState({
            form: formN,
            hasUploadedPhoto: true
        });
    }

    onDropPicrel(files) {
        let formN = this.state.form;
        formN.picrel = files[0].preview;
        formN.toSendPicRel = files[0];
        this.setState({
            form: formN,
            hasUploadedPhoto: true
        });
    }

    removeCoverPhoto() {
        let formN = this.state.form;
        formN.cover = '';
        this.setState({
            form: formN,
            hasUploadedPhoto: false
        });
    }

    removePicrel() {
        let formN = this.state.form;
        formN.picrel = '';
        this.setState({
            form: formN,
            hasUploadedPhoto: false
        });
    }

    handleSubmit = () => {

        const {form} = this.props;

        let formN = this.state.form;
        let qstr = Utils.serializeToUri(this.props.credentials);
        let self = this;
        let toSendCover = formN.cover;
        let toSendPicRel = formN.picrel;
        this.setState({launchLoader: true});

        if (this.state.hasUploadedPhoto) {
            toSendCover = formN.coverToSend;
            toSendPicRel = formN.toSendPicRel;
        }


        let data = new FormData();
        data.append('title', form.getFieldValue('title'));
        data.append('content', form.getFieldValue('content'));
        data.append('tags', form.getFieldValue('tags').join(' '));
        data.append('picrel', toSendPicRel);
        data.append('cover', toSendCover);
        data.append('credits', form.getFieldValue('credits'));

        if (typeof this.props.isEditing !== 'undefined') {

            data.append('id', this.props.isEditing);

            axios({
                url: ApiEndpoints.getUrl('editPasta') + qstr,
                method: 'post',
                data: data
            })
                .then(() => {
                    self.setState({
                        isAlertTriggered: true,
                        alertClass: 'alert_success',
                        alertText: 'Pomyślnie zaktualizowano pastę',
                        launchLoader: false,
                        form: {
                            picrel: "",
                            cover: "",
                            coverToSend: '',
                            toSendPicRel: '',
                        }
                    });
                    form.resetFields();
                    setTimeout(function () {
                        self.setState({isAlertTriggered: false});
                    }, 4000)
                })
                .catch(() => {
                    self.setState({
                        isAlertTriggered: true,
                        alertClass: 'alert_danger',
                        alertText: 'Nie udało się zedytować pasty',
                        launchLoader: false
                    });
                    setTimeout(function () {
                        self.setState({isAlertTriggered: false});
                    }, 4000)
                });
        } else {
            axios({
                url: ApiEndpoints.getUrl('addPasta') + qstr,
                method: "post",
                data: data
            })
                .then(() => {
                    self.setState({
                        isAlertTriggered: true,
                        alertClass: 'alert_success',
                        alertText: 'Pomyślnie dodano pastę',
                        launchLoader: false,
                        form: {
                            picrel: "",
                            cover: "",
                            coverToSend: '',
                            toSendPicRel: '',
                        }

                    });
                    form.resetFields();
                    setTimeout(function () {
                        self.setState({isAlertTriggered: false});
                    }, 4000)
                })
                .catch(() => {
                    self.setState({
                        isAlertTriggered: true,
                        alertClass: 'alert_danger',
                        alertText: 'Nie udało się dodać pasty',
                        launchLoader: false
                    });
                    setTimeout(function () {
                        self.setState({isAlertTriggered: false});
                    }, 4000)
                });
        }
    };

    validateBeforeSubmit = (event) => {
        event.preventDefault();
        const {form} = this.props;

        form.validateFields((errors, values) => {
            if (!errors) {
                this.handleSubmit();
            }
        });
    };

    componentWillMount() {
        let qstr = Utils.serializeToUri(this.props.credentials);
        let self = this;

        const {form} = this.props;

        if (typeof this.props.isEditing !== 'undefined') {

            this.setState({launchLoader: true});

            axios.get(ApiEndpoints.apiOrigin + ApiEndpoints.pastaInfo + this.props.isEditing + qstr)
                .then((response) => {

                    let tagArray = response.data.tags.map(text => text.text);

                    form.setFieldsValue({
                        ...response.data,
                        tags: tagArray
                    });

                    self.setState({
                        didGet: true,
                        launchLoader: false,
                    });
                })
                .catch(() => {
                    self.setState({
                        launchLoader: false
                    })
                });
        }
    }

    checkTitle = (rule, value, callback) => {

        if (value) {
            if (value.length >= 3)
                callback();
            else callback("Tytuł musi zawierać co najmniej 3 znaki!");
        } else callback();
    };

    render() {

        const {form} = this.props;
        const {getFieldDecorator} = form;

        let customAlert = null;
        let inputSubmitVal = 'Dodaj';
        let coverPreview = null;
        let picRelPreview = null;

        let tags = [];

        if (typeof form.getFieldValue('tags') !== "undefined") {
            tags = form.getFieldValue('tags').map((obj, i) => {
                return {
                    text: obj,
                    id: i,
                    key: i
                }
            });
        }

        if (this.state.isAlertTriggered) {
            customAlert = <Alert alertText={this.state.alertText} alertClass={this.state.alertClass}
                                 isAnimated={true} animationNameToOpen='fadeInDown' animationNameToFade='fadeOutUp'
                                 animationTime={2000}/>
        }

        if (typeof this.props.isEditing !== 'undefined') {
            inputSubmitVal = 'Edytuj';

        }


        let picCoverSrc = this.state.form.cover;
        let picRelSrc = this.state.form.picrel;

        if (typeof this.state.form.cover !== 'undefined' && this.state.form.cover !== '') {
            coverPreview = <div>
                Podgląd okładki:
                <div className="boxCentered previewBox">
                    <img className="maxWidthPicRel" src={picCoverSrc} role="presentation" alt='cover preview'/>
                    <div>
                        <button onClick={this.removeCoverPhoto.bind(this)} className="absolute x_button top_zero">X
                        </button>
                    </div>
                </div>
            </div>
        } else {
            coverPreview = null;
        }

        if (typeof this.state.form.picrel !== 'undefined' && this.state.form.picrel !== '') {

            picRelPreview = <div>
                Podgląd obrazka puenty:
                <div className="boxCentered previewBox">
                    <img className="maxWidthPicRel" src={picRelSrc} role="presentation" alt="pic rel preview"/>
                    <div>
                        <button onClick={this.removePicrel.bind(this)} className="absolute x_button top_zero">X</button>
                    </div>
                </div>
            </div>
        } else {
            picRelPreview = null;
        }

        return (
            <div className="marginTop">
                {customAlert}
                <Loader launchLoader={this.state.launchLoader}/>
                <Form className='form-add-pasta'>
                    <Form.Item
                        required={true}
                        colon={false}
                        label={<div className='form-label-wrapper'>
                            <div className='form-label-title'>Tytuł pasty</div>
                            <div className="small-font hint-text">Jeśli pasta nie ma tytułu
                                "obyczajowego"
                                postaraj się nadać samemu tytuł bez spojlerów oraz zawierający charakterystyczne
                                elementy
                                dla tej pasty
                            </div>
                        </div>}
                    >
                        {getFieldDecorator('title', {
                            validateTrigger: 'onBlur',
                            initialValue: '',
                            rules: [{
                                required: true,
                                message: "Tytuł musi zawierać co najmniej 3 znaki!",
                                min: 3,
                                whitespace: true
                            },
                                {
                                    validator: this.checkTitle,
                                }
                            ]
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item colon={false} required={true}
                               htmlFor='textarea'
                               label={<div className='form-label-wrapper'>
                                   <div className='form-label-title'>Treść pasty</div>
                                   <div className="small-font hint-text">Akceptowane: tekst oraz linki.
                                       Zakanae są
                                       reklamy, skrócone linki oraz reflinki
                                   </div>
                               </div>}
                    >
                        {getFieldDecorator('content', {
                            validateTrigger: 'onBlur',
                            rules: [{
                                required: true,
                                min: 5,
                                whitespace: true,
                                message: "Zawartość pasty nie może być pusta!"
                            }]
                        })(<TextArea rows="20" autoSize={{minRows: 5, maxRows: 20}}/>)}
                    </Form.Item>
                    <Form.Item colon={false}
                               label={<div className='form-label-wrapper'>
                                   <div className='form-label-title'>Tagi</div>
                                   <div className="small-font hint-text">Tagi ułatwiają znalezienie
                                       pasty.
                                       Oddzielane spacją. Bez znaku '#' przed tagiem
                                   </div>
                               </div>}
                    >
                        {getFieldDecorator('tags', {
                            validateTrigger: 'onBlur',
                        })(<Select mode='tags' tokenSeparators={[' ', ',']} notFoundContent=""/>)}
                    </Form.Item>
                    <Form.Item colon={false}
                               label={<div className='form-label-wrapper'>
                                   <div className='form-label-title'>Credits</div>
                                   <div className="small-font hint-text">(opcjonalne) Gdzie znaleziono
                                       tekst pasty
                                       | autor | link
                                   </div>
                               </div>}
                    >
                        {getFieldDecorator('credits', {initialValue: ''})(<Input/>)}
                    </Form.Item>
                    <Form.Item>
                        <div className="flex flex-column">
                            <div>
                                <label className='ant-form-item-no-colon'>
                                    <div>
                                        <div>Wrzuć okładkę. Max 200 kB, format tylko .jpg</div>
                                        <div className="small-font hint-text">(opcjonalne) Okładka
                                            przyciąga
                                            uwagę użytkowników = więcej wyświetleń pasty
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <span className="dropZoneDiv">
                                    <Dropzone maxSize={200000} accept="image/jpeg" ref='dropzoneCover'
                                              onDrop={this.onDropCover.bind(this)}>
                                        <span className="dropzone-description">Wrzuć okładkę</span>
                                    </Dropzone>
                                </span>
                        </div>
                        {coverPreview}
                    </Form.Item>
                    <Form.Item>
                        <div className="flex flex-column">
                            <div>
                                <label className='ant-form-item-no-colon'>
                                    <div>
                                        <div>
                                            Wrzuć obrazek puenty. Max 200 kB, tylko format .jpg
                                        </div>
                                        <div className="small-font hint-text">(opcjonalne) Obrazek,
                                            który
                                            trafnie podkreśli przesłanie pasty. Jeśli masz wrzucić coś słabego, to
                                            lepiej
                                            pomiń to pole.
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <span className="dropZoneDiv">
                                    <Dropzone maxSize={200000} accept="image/jpeg" ref='dropzonePicrel'
                                              onDrop={this.onDropPicrel.bind(this)}>
                                        <span className="dropzone-description">Wrzuć obrazek puenty</span>
                                    </Dropzone>
                                </span>
                        </div>
                        {picRelPreview}
                    </Form.Item>
                </Form>
                <h3>Podgląd pasty</h3>
                <h4>Upewnij się, czy wszystko jest na swoim miejscu.</h4>
                <Pasta
                    title={form.getFieldValue('title')}
                    author={this.props.user.name}
                    picRrel="" cover=""
                    author_id={this.props.user.id}
                    tags={tags} ids=""
                    views="37" votes='21'
                    confirmed={false}
                    isMoreButtonClicked={false}
                    author_photo=''
                    canDelete={false}
                    canEdit={false}
                    canHide={false}
                    canAddToFav={false}
                    canRemoveFromFav={false}
                    has_cover={0}
                    has_picrel={0}
                    hasPreview={true} previewCoverUrl={picCoverSrc} previewPicrelUrl={picRelSrc}
                    content={form.getFieldValue('content')}
                    credits={form.getFieldValue('credits')}
                    has_avatar={this.state.form.has_avatar}
                    contentRevealed={true}
                />
                <h3>Wszystko ok? Wyślij do świeżych past</h3>
                <div>
                    <Button htmlType='submit' size='large'
                            style={{marginBottom: '10px'}}
                            onClick={this.validateBeforeSubmit}
                    >
                        {inputSubmitVal}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        user: state.user
    };
};
export default connect(mapStateToProps)(Form.create()(PastaForm));