export default  class {
    static serializeToUri(obj) {
        if (typeof  obj === 'undefined') {
            return '';
        }
        return '?' + Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    }

    static encodePercentChar(string) {
        return string.replaceAll('%25', '%').replaceAll('%', '%25');
    }
}