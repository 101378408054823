import React from 'react'
import ApiEndpoints from './ApiEndpoints'
import PropTypes from 'prop-types'

class PicRel extends React.Component {
    render() {

        let picrel = null;

        if (this.props.has_picrel !== 0) {
            picrel = (
                <div className="boxCentered">
                    <img className="maxWidthPicRel fullWidth padding_top_20"
                         src={ApiEndpoints.apiOrigin + ApiEndpoints.pastaPicrel + this.props.id + '.jpg'}
                         alt='picrel'/>
                </div>
            );
        }

        return (
            <span>
                {picrel}
            </span>
        )
    }
}

PicRel.defaultProps = {
    has_picrel: 0
};

PicRel.propTypes = {
    has_picrel: PropTypes.number.isRequired
};

export default PicRel;