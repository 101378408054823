import React from 'react'
import {NavLink} from 'react-router-dom';

const CustomNavLink = (props) => {

    let {to, hasIcon, objIcon, objName, onItemClick} = props;

    let icon = null;

    if (hasIcon) {
        icon = <span className={"icon-" + objIcon + ' padding-right'}/>
    }

    return (
        <li className="alignVerticalMiddle paddingNavbarLink" onClick={onItemClick}>
            <NavLink to={to}
                     exact
                     activeClassName="activeLink">
                {icon}{objName}
            </NavLink>
        </li>
    )
};

export default CustomNavLink;
