import React from 'react'
import PastaForm from './AddPastaForm/PastaForm'

export default class AddPasta extends React.Component {

    render() {
        return (
            <div className="container" id="MainContent">
                <PastaForm isEditing={this.props.match.params.pastaId} />
            </div>
        );
    }
}