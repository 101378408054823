import React from 'react'
import CustomBadge from './CustomBadge'

class StatsComponent extends React.Component {
    render() {
        return (
            <div className="flex flex-row">
                <CustomBadge hasIcon={true}
                             badgeClassName="badgeVote"
                             iconBase="glyphicon"
                             iconDetails="glyphicon-thumbs-up"
                             badgeContent={this.props.score} />
                <CustomBadge hasIcon={true}
                             badgeClassName="badgeViews"
                             iconBase="icon"
                             iconDetails="icon-eye-open"
                             badgeContent={this.props.views} />
            </div>
        )
    }

}

StatsComponent.defaultProps = {
    score: 0,
    views: 0
};

export default StatsComponent;
