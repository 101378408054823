import React from 'react'
import { Badge } from 'react-bootstrap'

class CustomBadge extends React.Component {

    render()
    {
        let badgeClass = 'badge ' + this.props.badgeClassName;
        let icon = null;

        if(this.props.hasIcon)
        {
            icon = <span className={this.props.iconBase + ' ' + this.props.iconDetails}/>;
        }

        return (
            <Badge bsClass={badgeClass}>
                {icon}{this.props.badgeContent}
                {this.props.children}
            </Badge>
        )
    }
}

CustomBadge.defaultProps = {
    badgeContent: '',
    hasIcon: false,
    badgeClass: 'badge',
    badgeClassName: '',
    iconBase: '',
    iconDetails: ''
};

export default (CustomBadge);