import React from 'react'
import PastasList from './PastasList'
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from './Loader'
import AndroidAppLink from "./AndroidAppLink";
import {Helmet} from "react-helmet";

export default class Home extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            whichPage: 1,
            hasMoreItems: false,
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;

        this.setState({
            launchLoader: true
        });

        if (document.location.hash.indexOf('#') !== -1) {
            this.props.history.push(document.location.hash.replace('#/', ''));
        }

        axios.get(ApiEndpoints.getUrlForPage('pastasMain', this.state.whichPage))
            .then(function (response) {
                self.setState({pastas: response.data});
                axios.get(ApiEndpoints.getUrlForPage('hasMorePastasHome', self.state.whichPage))
                    .then(function (response) {
                        if (response.data) {
                            self.setState({
                                whichPage: self.state.whichPage + 1,
                                hasMoreItems: true,
                                launchLoader: false
                            })
                        } else {
                            self.setState({
                                hasMoreItems: false,
                                launchLoader: false
                            })
                        }
                    })
                    .catch(function () {
                        self.setState({
                            hasMoreItems: false,
                            launchLoader: false
                        });
                    })
            })
            .catch(() => {
                self.setState({
                    launchLoader: false
                })
            });

    }


    loadMoreContent() {
        let self = this;

        axios.get(ApiEndpoints.getUrlForPage('pastasMain', this.state.whichPage))
            .then(function (response) {
                self.setState({pastas: self.state.pastas.concat(response.data)});
                axios.get(ApiEndpoints.getUrlForPage('hasMorePastasHome', self.state.whichPage))
                    .then(function (response) {
                        if (response.data) {
                            self.setState({
                                whichPage: self.state.whichPage + 1,
                                hasMoreItems: true
                            })
                        } else {
                            self.setState({
                                hasMoreItems: false
                            })
                        }
                    })
                    .catch(function () {
                        self.setState({
                            hasMoreItems: false
                        });
                    })
            });
    }


    render() {

        let metaTags = (
            <Helmet>
                <title>Gorące pasty - Copypasta.pl</title>
                <meta name="description" content='Wyselekcjonowane pasty specjalnie dla Ciebie'/>
                <meta name="keywords" content='pasty gorące Copypasta.pl '/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Gorące pasty - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description" content='Wyselekcjonowane pasty specjalnie dla Ciebie'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='Gorące pasty - Copypasta.pl'/>
                <meta name="twitter:description" content='Wyselekcjonowane pasty specjalnie dla Ciebie'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    <AndroidAppLink/>
                    <InfiniteScroll initialLoad={true} threshold={300} loadMore={this.loadMoreContent.bind(this)}
                                    hasMore={this.state.hasMoreItems}>
                        <PastasList
                            canAddToFav={true}
                            canRemoveFromFav={false}
                            canHide={false}
                            canDelete={false}
                            canEdit={false}
                            listOfPastas={this.state.pastas}/>
                    </InfiniteScroll>
                </div>
            </div>

        )
    }
}