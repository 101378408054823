import React from 'react'
import ApiEndpoints from './ApiEndpoints'
import PropTypes from 'prop-types'

class CoverPhotoPreview extends React.Component {
    render() {

        let coverPhoto = null;

        if (this.props.has_cover !== 0) {
            coverPhoto =
                <div className="maxWidth fullWidth imagePasta ">
                    <img src={ApiEndpoints.apiOrigin + ApiEndpoints.pastaCoverPhoto + this.props.id + '.jpg'}
                         alt='cover preview'/>
                </div>;
        } else {
            if (this.props.previewCoverUrl !== '') {
                coverPhoto =
                    <div className="fullWidth imagePasta ">
                        <img src={this.props.previewCoverUrl}
                             alt='cover preview'/>
                    </div>;
            }
        }

        return (
            <span>
                {coverPhoto}
            </span>
        )
    }
}

CoverPhotoPreview.defaultProps = {
    has_cover: 0
};

CoverPhotoPreview.propTypes = {
    has_cover: PropTypes.number.isRequired
};

export default CoverPhotoPreview;