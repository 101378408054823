import React from 'react'
import {connect} from 'react-redux'
import UsersColors from './UsersColors'
import Utils from './Utils';
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import Dropzone from 'react-dropzone'
import Alert from './Alert'
import Loader from './Loader'
import IncludeIcon from './IncludeIcon'
import {Helmet} from "react-helmet";

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state =
            {
                ...props,
                isPhotoChangeTriggered: false,
                photoPreview: '',
                isAlertTriggered: false,
                alertText: '',
                alertContent: '',
                launchLoader: false,
                isChangeNameTriggered: false,
                user: typeof (props.user) !== "undefined" ? props.user : {name: ''},
            };
    }

    triggerPhotoChange() {
        this.setState({isPhotoChangeTriggered: !this.state.isPhotoChangeTriggered});
    }

    triggerNameChange(event) {
        event.preventDefault();

        this.setState({
            isChangeNameTriggered: !this.state.isChangeNameTriggered
        })
    }

    changeName(event) {
        this.setState({
            user: {
                name: event.target.value
            }
        });
    }

    applyChanges(event) {
        event.preventDefault();
        let arg = Utils.serializeToUri(Object.assign(this.props.credentials, {name: this.state.user.name}));

        this.setState({
            launchLoader: true
        });

        let self = this;
        axios.get(ApiEndpoints.getUrl('userName') + arg)
            .then(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie zaktualizowano nazwę użytkownika',
                    launchLoader: false,
                    isChangeNameTriggered: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się zaktualizować nazwy użytkownika',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        let qstr = Utils.serializeToUri(this.props.credentials);
        let data = new FormData();
        data.append('file', this.state.photoPreview);
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios({
            url: ApiEndpoints.getUrl('userPhotoChange') + qstr,
            method: "post",
            data: data
        })
            .then(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie zaktualizowano zdjęcie',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się zaktualizować zdjęcia',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });

    }

    onDrop(files) {
        this.setState({
            photoPreview: files[0]
        });
    }

    render() {
        let usr = null;
        let alertCustom = null;
        let btnToggleNameChange = null;
        let photoChange = null;
        let buttonToggleChangePhoto = null;
        let picturePreview = null;


        if (typeof this.props.user !== 'undefined') {

            if (this.state.isChangeNameTriggered) {
                btnToggleNameChange = (
                    <div>
                        <input value={this.state.user.name} className="form-control input-md"
                               onChange={this.changeName.bind(this)}/>
                        <button className="btn btn-default margin-top" onClick={this.applyChanges.bind(this)}>
                            Zmień nazwę
                        </button>
                    </div>
                );
            }

            usr = (
                <p>
                    <UsersColors role={this.props.user.role} userName={this.state.user.name}/>
                    <button onClick={this.triggerNameChange.bind(this)}
                            className="btn noBg noBoxShadowHover link_color">
                        <IncludeIcon iconBase="icon" iconDetails="icon-edit"/>
                    </button>
                    {btnToggleNameChange}
                </p>
            );

            buttonToggleChangePhoto =
                <button className="btn btn-default" onClick={this.triggerPhotoChange.bind(this)}>Zmień obrazek
                    avatara</button>;

            if (this.state.isPhotoChangeTriggered) {
                if (this.state.photoPreview !== '') {
                    picturePreview =
                        <span>Podgląd zdjęcia: <img className="imgUser" src={this.state.photoPreview.preview}
                                                    alt='user icon'/></span>
                }
                photoChange = (
                    <div>
                        <Dropzone maxSize="15000" ref='dropzone' onDrop={this.onDrop.bind(this)}>
                            <div>Wrzuć pliki max 15kb, format tylko .jpg</div>
                        </Dropzone>
                        {picturePreview}
                        <button className="btn btn-default" onClick={this.handleSubmit.bind(this)}>Zaktualizuj zdjęcie
                        </button>
                    </div>
                );
            }
        } else {
            usr = <p>Zaloguj sie</p>;
        }

        if (this.state.isAlertTriggered) {

            alertCustom = <Alert alertText={this.state.alertText} alertClass={this.state.alertClass}
                                 isAnimated={true} animationNameToOpen='fadeInDown' animationNameToFade='fadeOutUp'
                                 animationTime={2000}/>

        }

        let metaTags = (
            <Helmet>
                <title>Konto użytkownika - Copypasta.pl</title>
                <meta name="description" content='Konto użytkownika serwisu Copypasta.pl'/>
                <meta name="keywords" content='Konto użytkownik Copypasta.pl'/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Konto użytkownika - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description" content='Konto użytkownika serwisu Copypasta.pl'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='Konto użytkownika - Copypasta.pl'/>
                <meta name="twitter:description" content='Konto użytkownika serwisu Copypasta.pl'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                {alertCustom}
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    <h1>Konto</h1>
                    {usr}
                    {buttonToggleChangePhoto}
                    {photoChange}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        credentials: state.credentials
    };
};
export default connect(mapStateToProps)(Account);