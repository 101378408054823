export default  {
    apiOrigin: 'https://api.copypasta.pl/',
    frontOrigin: 'https://copypasta.pl/',
    playStoreAndroidApp: 'https://bit.ly/2VxFOGX',
    //pasta routes
    pastasMain: 'pastas',                  // + pageNum parameter (infinite scrolling)
    pastasFresh: 'pastas/fresh',           //+ /pageNum (max 5) BACKSLASH IMPORTANT
    pastasGold: 'pastas/gold',             //+ pageNum (max 5)
    pastaInfo: 'pasta/',                   //id parameter
    addPasta: 'pasta',                     //post!!!
    confirmPasta: 'pasta/confirm/',
    fbAppId: '357061461323643',
    fbPageId: '1835559280020150',
    pastasRecent: 'pastas/recent',
    pastaFavourite: 'pasta/favourite/',
    pastasFavourite: 'pastas/favourite',
    userPastas: 'user/me/pastas',
    userPhotoChange: 'user/photo/change',
    editPasta: 'pastaupdate',
    removePasta: 'pasta/',
    getUserPhoto: 'img/avatars/',
    pastaCoverPhoto: 'img/covers/',
    pastaPicrel: 'img/picrels/',
    hasMorePastasHome: 'pastas/more',
    hasMorePastasGold: 'pastas/gold/more',
    hasMorePastasFresh: 'pastas/fresh/more',
    hidePasta: 'pasta/hide/',
    similarPastas: 'pastas/similar/',
    bumpPasta: "pasta/bump/",
    //voting
    getUpVoteUrl: function () {
        return this.getUrl('pastaInfo') + 'vote';
    },

    getDownVoteUrl: function () {
        return this.getUrl('pastaInfo') + 'vote';
    },

    //mode
    getConfirmUrl: function (pastaId) {
        return this.apiOrigin + '/pasta/' + pastaId + '/confirm/';
    },

    //search
    searchTitle: '/search/title?text=',          //text param
    searchTag: '/search/tag?text=',            //text param
    searchContent: 'search/q',            //text param


    //user routes
    changePhoto: 'user/photo/change/',
    changeName: 'user/name/',
    userInfo: 'user/info/',
    currentUserInfo: 'user/me',
    giveMode: 'user/mode',  //post
    usersPastas: 'user/pastas/',
    userName: 'user/name',

    getUrl: function (prop) {
        return this.apiOrigin + this[prop];
    },
    getUrlForPage: function (prop, page = 1) {
        return this.getUrl(prop) + '/' + page;
    },
};