import React from 'react'

class VotesPasta extends React.Component {
    render() {
        let voteColor;
        let sign = null;


        if(this.props.votes > 0)
        {
            voteColor = 'positiveStat';
            sign = '+';
        }
        else if (this.props.votes < 0)
        {
            voteColor = 'negativeStat';
        }
        else {
            voteColor = 'neutralStat';
        }

        return (
            <span className={voteColor}>{sign}{this.props.votes}</span>
        )
    }
}

VotesPasta.defaultProps = {
    votes: 0
};

export default (VotesPasta);