import React, { Component } from 'react';
import CoverPhoto from './CoverPhoto'
import ApiEndpoints from "./ApiEndpoints";
import UsersColors from "./UsersColors";
import TriggerPopUpUser from "./TriggerPopUpUser";
import {Link} from "react-router-dom";

class PastaPill extends Component {

    render() {

        let MoreButtonAddClass = 'btnMore';

        if (this.props.has_cover !== 0) {
            MoreButtonAddClass = 'noCoverPhotoMoreBtn';
        }

        let slug = '';
        if (typeof this.props.title !== 'undefined') {
            slug = this.props.title.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").split(' ').join('-');
        }

        let moreButton = <span className={`${MoreButtonAddClass} btn noBoxShadowHover noBg`}
            ><Link className={`${MoreButtonAddClass} btn noBoxShadowHover noBg text-underline-hover`} to={`/pasta/${this.props.id}/${slug}`}>
            Czytaj całość <span className="icon icon-circledown"/></Link></span>;

        let coverPhoto = (
            <CoverPhoto has_cover={this.props.has_cover}
                        shortContent={this.props.shortContent}
                        id={this.props.id} isMoreButtonClicked={false}>
                {moreButton}
            </CoverPhoto>);

        let popUpUser = null;
        let avatar = null;

        if(typeof this.props.author_id !== 'undefined')
        {

            if(this.props.has_avatar !== 0)
            {
                avatar = (
                    <img
                        src={ApiEndpoints.apiOrigin + ApiEndpoints.getUserPhoto + this.props.author_id + '.jpg'}
                        className='imgUser' alt="author avatar"/>
                );
            }

            popUpUser = (
                <TriggerPopUpUser userRole={this.props.role} userId={this.props.author_id}>
                                    <span className="pasta_author">
                                        <span className="author_avatar">
                                            {avatar}
                                        </span>
                                        <span><UsersColors role={this.props.role} userName={this.props.author}/></span>
                                    </span>
                </TriggerPopUpUser>
            );
        }

        return (
            <div className="pasta pasta_pill">
                <div className="containerStatsTitle">
                    <div className="display_block">
                        <div className="pasta_title"><Link to={`/pasta/${this.props.id}/${slug}`}>{this.props.title}</Link></div>
                        {popUpUser}
                    </div>

                </div>
                <div>
                    <article>
                        {coverPhoto}
                    </article>
                </div>
            </div>
        );
    }
}

export default PastaPill;