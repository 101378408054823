import React from 'react'

export default class ContactWithUs extends React.Component {

    render() {
        return (
            <div className="container">
                <div className="marginTop">
                    <span className="pasta_content">
                        <h1>Kontakt</h1>

                        <p>
                            <a href='https://www.facebook.com/Copypasta-1835559280020150/' className="link_underline" >Zapraszamy do kontaktu przez FanPage'a </a>
                        </p>
                    </span>
                </div>
            </div>

        )
    }
}