import React from 'react'
import Tags from './Tags'
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {Login, ShareButton} from 'react-facebook';
import Utils from './Utils';
import UsersColors from './UsersColors'
import Alert from './Alert'
import VotesPasta from './VotesPasta'
import CustomBadge from './CustomBadge'
import TriggerPopUpUser from './TriggerPopUpUser'
import Loader from './Loader'
import CoverPhoto from './CoverPhoto'
import CoverPhotoPreview from './CoverPhotoPreview'
import PicRel from './PicRel'
import PicRelPreview from './PicRelPreview'
import Text from 'react-format-text'
import SimilarPastas from "./SimilarPastas";
import {copyToClipboard} from "./copyToClipboard";
import {Helmet} from "react-helmet";
import IncludeIcon from './IncludeIcon';
import {Button} from 'antd';
import {bool} from 'prop-types';

class Pasta extends React.Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, props,
            {
                isAlertTriggered: false,
                alertClass: '',
                alertText: '',
                showUserPopup: false,
                userInfo: '',
                launchLoader: false,
                hasLiked: '',
                hasDisliked: '',
                listOfPastasPills: [],
                loadedData: false
            });

        this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);

    }

    handleClick() {
        let self = this;
        let qstr = Utils.serializeToUri(this.props.credentials);

        this.setState({
            isMoreButtonClicked: true,
            launchLoader: true,
            contentRevealed: true,
        });

        axios.get(ApiEndpoints.getUrl('pastaInfo') + this.state.ids + qstr)
            .then(function (response) {
                self.setState(Object.assign({}, response.data,
                    {
                        launchLoader: false,
                    }
                ));
            });

        axios.get(ApiEndpoints.getUrl('similarPastas') + this.state.ids + qstr)
            .then(function (response) {
                self.setState({
                    listOfPastasPills: response.data,
                    loadedData: true,
                });
            })
            .catch(function (response) {
                self.setState({
                    listOfPastasPills: [],
                });
            });
    }

    upVoteClick() {
        let self = this;
        this.setState({launchLoader: true});

        let obj = Object.assign({
            id: self.state.ids,
            val: 1
        }, self.props.credentials);

        let qstr = Utils.serializeToUri(obj);

        axios.get(ApiEndpoints.getUpVoteUrl() + qstr)
            .then(() => {
                let votes = parseInt(self.state.votes, 10) + 1;
                self.setState({
                    votes: votes,
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Głos oddany',
                    launchLoader: false,
                    hasLiked: 'liked'
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                self.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_warning',
                    alertText: 'Już głosowano',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    }

    downVoteClick() {
        let self = this;
        let obj = Object.assign({
            id: self.state.ids,
            val: -1
        }, self.props.credentials);
        let qstr = Utils.serializeToUri(obj);

        this.setState({launchLoader: true});

        axios.get(ApiEndpoints.getDownVoteUrl() + qstr)
            .then(() => {
                let votes = parseInt(self.state.votes, 10) - 1;
                self.setState({
                    votes: votes,
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Głos oddany',
                    launchLoader: false,
                    hasDisliked: 'disliked'
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            }).catch(() => {
            self.setState({
                isAlertTriggered: true,
                alertClass: 'alert_warning',
                alertText: 'Już głosowano',
                launchLoader: false
            });
            setTimeout(function () {
                self.setState({isAlertTriggered: false});
            }, 4000)
        });
    }

    confirmPastaClick() {
        this.props.confirm_pasta(this);
    }

    toggleFavourites() {
        let qstr = Utils.serializeToUri(this.props.credentials);

        this.setState({launchLoader: true});
        let self = this;

        axios.get(ApiEndpoints.getUrl('pastaFavourite') + this.state.ids + qstr)
            .then(() => {
                self.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie dodano pastę do ulubionych',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                self.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się dodać pasty do ulubionych',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    }

    onFacebookResponse = (response) => {
        const {tokenDetail} = response;
        const {userID, accessToken} = tokenDetail;
        let usr = {};
        usr.id = userID;
        usr.authToken = accessToken;
        this.props.setUsr(usr);
    };

    removePasta() {
        this.props.delete_pasta(this);
    }

    hidePasta() {
        this.props.hide_pasta(this);
    }

    removeFav() {
        this.props.removeFavourites(this);
    }

    shareInMessenger() {
        window.open('fb-messenger://share?link=' + encodeURIComponent(ApiEndpoints.frontOrigin + `pasta/${this.state.ids}`) + '&app_id=' + encodeURIComponent(357061461323643));
    }

    bumpPasta = () => {
        let qstr = Utils.serializeToUri(this.props.credentials);

        this.setState({launchLoader: true});
        let self = this;
        axios.get(ApiEndpoints.getUrl('bumpPasta') + this.state.ids + qstr)
            .then(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie dodanobumpnięto pastę',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się bumpnąć pasty',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    };

    handleCopyToClipboard(event) {
        event.preventDefault();

        let res = copyToClipboard(this.props.ids);

        let self = this;

        let alertClass = 'alert_success';
        let alertMessage = 'Pomyślnie skopiowano tekst do schowka';

        if (!res) {
            alertClass = 'alert_danger';
            alertMessage = 'Nie udało się skopiować tekstu do schowka';
        }

        this.setState({
            isAlertTriggered: true,
            alertClass: alertClass,
            alertText: alertMessage,
        });
        setTimeout(function () {
            self.setState({isAlertTriggered: false});
        }, 4000)

    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);

    }

    componentDidMount() {

        if (this.props.redirectedFromLink && typeof (this.state.ids) !== "undefined") {
            let self = this;
            let qstr = Utils.serializeToUri(this.props.credentials);

            axios.get(ApiEndpoints.getUrl('similarPastas') + self.state.ids + qstr)
                .then(function (response) {
                    self.setState({
                        listOfPastasPills: response.data,
                        loadedData: true,
                    });
                })
                .catch(function (response) {
                    self.setState({
                        listOfPastasPills: [],
                    });
                });
        }
    }

    render() {

        let alertCustom = null;
        let moreButton = null;
        let voting = null;

        let {loadedData, isMoreButtonClicked, listOfPastasPills, contentRevealed} = this.state;
        let {redirectedFromLink} = this.props;

        if (this.state.isAlertTriggered) {

            alertCustom = <Alert alertText={this.state.alertText} alertClass={this.state.alertClass}
                                 isAnimated={true} animationNameToOpen='fadeInDown' animationNameToFade='fadeOutUp'
                                 animationTime={2000}/>

        }

        let MoreButtonAddClass = 'btnMore';

        if (this.props.has_cover !== 0) {
            MoreButtonAddClass = 'noCoverPhotoMoreBtn';
        }

        if (!contentRevealed) {
            moreButton = <button className={MoreButtonAddClass + ' btn noBoxShadowHover noBg'}
                                 onClick={this.handleClick.bind(this)}>Czytaj całość <span
                className="icon icon-circledown"/></button>;
        } else {
            if (typeof this.props.credentials !== 'undefined') {
                voting = (
                    <span className="pastaVote">
                        <button className={this.state.hasLiked + ' btn like'}
                                onClick={this.upVoteClick.bind(this)}><span
                            className="glyphicon glyphicon-thumbs-up"/></button>
                        <button className={this.state.hasDisliked + ' btn dislike'}
                                onClick={this.downVoteClick.bind(this)}><span
                            className="glyphicon glyphicon-thumbs-down"/></button>
                    </span>
                );
            } else {
                voting = (
                    <span className="lightPadding-left link_color">
                        <Login scope="public_profile" onCompleted={this.onFacebookResponse}>
                            {({handleClick, loading}) => (
                                <Button type='link' loading={loading} disabled={loading} onClick={handleClick}
                                        className="btn noBg noBoxShadowHover link_underline"><strong>Zaloguj się aby móc
                                    głosować</strong></Button>
                            )}
                        </Login>
                    </span>);
            }
        }

        let slug = '';
        if (typeof this.state.title !== 'undefined') {
            slug = Utils.encodePercentChar(this.state.title.trim()).normalize('NFD').replace(/[\u0300-\u036f]/g, "").split(' ').join('-');
        }

        let confirm = null;
        if (typeof this.props.user !== 'undefined'
            && this.props.confirmed !== 'undefined'
            && this.props.confirmed === false) {
            if (this.props.user.role === 'root' || this.props.user.role === 'mode') {
                confirm =
                    <button className="btn btn-default" onClick={this.confirmPastaClick.bind(this)}>Zatwierdź</button>
            }
        }

        let userRole = '';

        if (typeof this.props.user !== 'undefined') {
            userRole = this.props.user;
        }


        let showContent = null;

        let favourites = null;
        let canRemoveFromFav = null;
        let credits = null;

        if (this.props.credits !== null && this.props.credits.length > 0) {
            credits = (
                <div className="text-right text-italic padding-top">
                    Credits: <strong>{this.props.credits}</strong>
                </div>
            )
        }


        let coverPhoto = (
            <CoverPhoto has_cover={this.props.has_cover}
                        shortContent={this.state.shortContent}
                        id={this.state.ids} isMoreButtonClicked={this.state.isMoreButtonClicked}>
                {moreButton}
            </CoverPhoto>);

        let picrel = (
            <PicRel has_picrel={this.props.has_picrel} id={this.state.ids}/>
        );

        let tags = (
            <Tags taglist={this.state.tags}/>
        );

        if (typeof this.props.credentials !== 'undefined') {
            if (this.state.canAddToFav) {
                favourites = (
                    <span>
                        <button className="btn noBg link_underline link_color noBoxShadowHover"
                                onClick={this.toggleFavourites.bind(this)}><span className="icon icon-star"/> Dodaj do ulubionych</button>
                    </span>
                );
            }

            if (this.state.canRemoveFromFav) {
                canRemoveFromFav = (
                    <span>
                            <button className="btn noBg link_underline link_color noBoxShadowHover"
                                    onClick={this.removeFav.bind(this)}>Usuń z ulubionych</button>
                        </span>
                );
            }

        }

        if (this.state.isMoreButtonClicked) {

            if (typeof this.state.content !== 'undefined') {

                let text = this.state.content;
                text = text.replace(/\r/g, '');

                showContent = (
                    <div className="pasta_content">
                        <div id={this.state.ids}>
                            <div
                                className="display_none">{ApiEndpoints.frontOrigin + `pasta/${this.state.ids}/${slug}`}</div>
                            <Text>{text}</Text>
                        </div>
                        {credits}
                        {picrel}
                    </div>
                );
            }
        }

        if (this.state.hasPreview) {
            picrel = (
                <PicRelPreview previewPicrelUrl={this.state.previewPicrelUrl}/>
            );

            tags = <Tags taglist={this.state.tags}/>;

            if (typeof this.state.content !== 'undefined') {

                coverPhoto = (<CoverPhotoPreview has_cover={this.props.has_cover} id={this.state.ids}
                                                 previewCoverUrl={this.state.previewCoverUrl}/>);

                showContent = (<div className="pasta_content">
                    <div id={this.state.ids}>
                        <div
                            className="display_none">{ApiEndpoints.frontOrigin + `pasta/${this.state.ids}/${slug}`}</div>
                        <Text>{this.state.content}</Text>
                    </div>
                    {credits}
                    {picrel}
                </div>);
            }
        }

        if (this.props.redirectedFromLink) {
            moreButton = null;

            coverPhoto = (
                <CoverPhoto has_cover={this.props.has_cover}
                            id={this.state.ids} isMoreButtonClicked={true}/>
            );

            if (typeof this.state.content !== 'undefined') {
                let text = this.state.content;
                text = text.replace(/\r/g, '');

                showContent = (
                    <div className="pasta_content">
                        <div id={this.state.ids}>
                            <div
                                className="display_none">{ApiEndpoints.frontOrigin + `pasta/${this.state.ids}/${slug}`}</div>
                            <Text>{text}</Text>
                        </div>
                        {credits}
                        {picrel}

                    </div>
                );

            }
        }

        let edit = null;
        if (this.state.canEdit || (this.props.user && this.props.user.role === 'root')) {

            if (this.props.user.role === 'root' || !this.props.confirmed) {
                edit = (
                    <Link to={'/user/pastas/edit/' + this.state.ids}
                          className="btn noBg link_color link_underline"><span
                        className="icon icon-edit"/> Edytuj</Link>
                );
            }

        }

        let canDelete = null;
        if (this.state.canDelete) {
            if (!this.props.confirmed) {
                canDelete = (
                    <button onClick={this.removePasta.bind(this)} className="btn noBg link_delete link_underline"><span
                        className="icon icon-trash"/> Usuń</button>
                );
            }

        }

        let canHide = null;
        if (this.state.canHide) {

            if (this.props.user.role === 'root') {
                canHide = (
                    <button onClick={this.hidePasta.bind(this)} className="btn noBg link_underline link_color">
                        Ukryj</button>
                );
            }

            if (!this.props.confirmed) {
                canHide = (
                    <button onClick={this.hidePasta.bind(this)} className="btn noBg link_underline link_color">
                        Ukryj</button>
                );
            }

        }

        let bumpButton = null;

        if (this.props.user) {
            if (this.props.user.role === "mode" || this.props.user.role === 'root') {
                bumpButton = (
                    <button onClick={this.bumpPasta} className="btn noBg link_color link_underline">
                        <span className="icon icon-lightning">Bump</span>
                    </button>
                )
            }
        }

        let popUpUser = null;
        let avatar = null;

        if (typeof this.state.author_id !== 'undefined') {

            if (this.props.has_avatar !== 0) {
                avatar = (
                    <img
                        src={ApiEndpoints.apiOrigin + ApiEndpoints.getUserPhoto + this.state.author_id + '.jpg'}
                        className='imgUser' alt="author avatar"/>
                );
            }

            popUpUser = (
                <TriggerPopUpUser userRole={userRole} userId={this.state.author_id}>
                                    <span className="pasta_author">
                                        <span className="author_avatar">
                                            {avatar}
                                        </span>
                                        <span><UsersColors role={this.state.role} userName={this.state.author}/></span>
                                    </span>
                </TriggerPopUpUser>
            );
        }

        let additionalFocusPilledClass = "";
        if (!this.props.isMoreButtonClicked) {
            let date = new Date(this.props.created_at);
            let now = new Date();
            let twoDaysEarlier = new Date();
            twoDaysEarlier.setDate(now.getDate() - 2);
            if (twoDaysEarlier.getTime() <= date.getTime()) {
                additionalFocusPilledClass = "focus_pilled_pasta";
            }
        }

        let metaTags = (
            <Helmet>
                <title>{`${this.state.title} - Copypasta.pl`}</title>
                <meta name="description" content={this.props.shortContent}/>
                <meta name="keywords" content={this.props.tags}/>
                <meta name="author" content={this.props.author}/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content={this.state.title}/>
                <meta property="og:type" content="article"/>
                <meta property="og:image"
                      content={ApiEndpoints.apiOrigin + ApiEndpoints.pastaCoverPhoto + this.props.ids + '.jpg'}/>
                <meta property="og:url" content={ApiEndpoints.frontOrigin + `pasta/${this.state.ids}/${slug}`}/>
                <meta property="og:description" content={this.props.shortContent}/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={this.state.title}/>
                <meta name="twitter:description" content={this.props.shortContent}/>
                <meta name="twitter:image"
                      content={ApiEndpoints.apiOrigin + ApiEndpoints.pastaCoverPhoto + this.props.ids + '.jpg'}/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className={`pasta ${additionalFocusPilledClass}`}>

                {this.props.isStandalone && metaTags}
                <Loader launchLoader={this.state.launchLoader}/>
                {alertCustom}
                <div className="containerStatsTitle">
                    <div className="flex flex-row">
                        <div className="display_block">
                            <div className="pasta_title">
                                <Link to={`/pasta/${this.state.ids}/${slug}`}>{this.state.title}</Link>
                                {popUpUser}
                            </div>
                        </div>
                        <div className="stats">
                            <span className="statsDiv pullRight flex">
                                <span className="data disableOnMobile">
                                    {this.props.created_at}
                                </span>
                                <span className="views ">
                                        <CustomBadge hasIcon={true}
                                                     badgeClassName="badgeViews"
                                                     iconBase="icon"
                                                     iconDetails="icon-eye-open">
                                            {this.state.views}
                                        </CustomBadge>
                                </span>
                                <span className="voteStatTitle">
                                        <CustomBadge hasIcon={true}
                                                     badgeClassName="badgeVote"
                                                     iconBase="glyphicon"
                                                     iconDetails="glyphicon-thumbs-up">
                                            <VotesPasta votes={this.state.votes}/>
                                        </CustomBadge>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="hasztags disableOnMobile">
                        <div className="pasta_tags">
                            {tags}
                        </div>
                    </div>
                </div>
                <div>
                    <article>
                        {coverPhoto}
                        {showContent}

                        <div
                            className="border-top light-grey flex-column-mobile display_flex flex-row text-center-mobile">
                            {voting}
                            <ShareButton href={ApiEndpoints.frontOrigin + `pasta/${this.state.ids}`}
                                         className="btn noBg link_underline link_color noBoxShadowHover">
                                <span className="icon icon-share"/> Udostępnij na FB
                            </ShareButton>
                            <button className="btn noBg link_color link_underline disableOnDesktop"
                                    onClick={this.shareInMessenger.bind(this)}>
                                <IncludeIcon iconBase="icon" iconDetails="icon-commentroundtyping"/> Wyślij przez
                                Messenger
                            </button>
                            {favourites}
                            {edit}
                            {canDelete}
                            {canHide}
                            {canRemoveFromFav}
                            {bumpButton}
                            {isMoreButtonClicked &&
                            <button className="btn noBg link_underline link_color noBoxShadowHover"
                                    onClick={(event) => this.handleCopyToClipboard(event)}>
                                <span className="icon icon-clipboardalt"> </span>
                                Skopiuj do schowka
                            </button>}
                        </div>

                        {(isMoreButtonClicked || redirectedFromLink) && loadedData &&
                        <SimilarPastas listOfPastasPills={listOfPastasPills}/>}

                    </article>
                    <div className="pasta_footer flex flex-rowreverse">
                        <span className="views flex flex-rowreverse">
                            {confirm}
                        </span>

                    </div>
                    <div>

                    </div>
                </div>
                <div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUsr: (usr) => {
            let credentials = {
                user_ID: usr.id,
                api_token: usr.authToken
            };
            dispatch({type: 'USER_FB_TOKEN_RECEIVED', payload: credentials});
            let qstr = Utils.serializeToUri(credentials);
            axios.get(ApiEndpoints.getUrl('currentUserInfo') + qstr).then((response) => {
                dispatch({
                    type: "USER_LOGGED_IN", payload: response.data
                });
            });
        },
    };
};

Pasta.propTypes = {
    contentRevealed: bool
}

Pasta.defaultProps = {
    hasPreview: false,
    previewCoverUrl: '',
    previewPicrelUrl: '',
    redirectedFromLink: false,
    credits: '',
    contentRevealed: false
};
export default connect(mapStateToProps, mapDispatchToProps)(Pasta);