import React from 'react'
import ApiEndpoints from "./ApiEndpoints";

export default class AndroidAppLink extends React.Component {


    render() {
        return (
            <div className='text-center app-link'>
                Aplikacja mobilna na system Android już dostępna! <a href={ApiEndpoints.playStoreAndroidApp} target="_blank" rel="noopener noreferrer">Kliknij tutaj</a>
            </div>
        )
    }

}