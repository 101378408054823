import React from 'react'
import Pasta from './Pasta'
import ApiEndpoints from './ApiEndpoints'
import axios from 'axios'
import {connect} from 'react-redux';
import Utils from './Utils';
import Loader from './Loader'
import Alert from "./Alert";

class PastaDetails extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            formP: this.props.match.params,
            launchLoader: false,
            formN: {},
            canEdit: false,
            canDelete: false,
            canHide: false,
            canRemoveFromFav: false,
            canAddToFav: true
        }
    }

    componentWillReceiveProps(nextProps) {
        let self = this;

        this.setState({
            launchLoader: true
        });

        if (!(this.props.match.params.pastaId === nextProps.match.params.pastaId)) {
            let qstr = Utils.serializeToUri(this.props.credentials);
            axios.get(ApiEndpoints.getUrl('pastaInfo') + nextProps.match.params.pastaId + qstr)
                .then(function (response) {
                    self.setState({
                        formN: response.data,
                        launchLoader: false
                    });
                });
        } else {
            let self = this;
            this.setState({isAlertTriggered: true, launchLoader: false});
            setTimeout(function () {
                self.setState({isAlertTriggered: false});
            }, 4000);
        }
    }

    componentDidMount() {
        let self = this;
        let qstr = Utils.serializeToUri(this.props.credentials);

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('pastaInfo') + this.state.formP.pastaId + qstr)
            .then(function (response) {
                self.setState({
                    formN: response.data,
                    launchLoader: false
                });
            });
    }

    render() {

        let alertCustom = null;
        if (this.state.isAlertTriggered) {
            alertCustom = <Alert alertText={"Już przeglądasz tę pastę"} alertClass={"alert_warning"}
                                 isAnimated={true} animationNameToOpen='fadeInDown' animationNameToFade='fadeOutUp'
                                 animationTime={2000}/>;
        }

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {alertCustom}

                <div className="marginTop">
                    <Pasta key={this.state.formN.id} shortContent={this.state.formN.short_content}
                           title={this.state.formN.title} author={this.state.formN.author}
                           content={this.state.formN.content}
                           tags={this.state.formN.tags} ids={this.state.formN.id}
                           author_id={this.state.formN.author_id}
                           views={this.state.formN.views} votes={this.state.formN.votes}
                           isMoreButtonClicked={false} role={this.state.formN.role}
                           author_photo={this.state.formN.author_photo}
                           canEdit={this.state.canEdit} canDelete={this.state.canDelete}
                           canHide={this.state.canHide}
                           canRemoveFromFav={this.state.canRemoveFromFav}
                           canAddToFav={this.state.canAddToFav}
                           has_cover={this.state.formN.has_cover}
                           has_picrel={this.state.formN.has_picrel}
                           redirectedFromLink={true}
                           credits={this.state.formN.credits}
                           has_avatar={this.state.formN.has_avatar} created_at={this.state.formN.created_at}
                           isStandalone={true}
                           contentRevealed={true}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {credentials: state.credentials};
};
export default connect(mapStateToProps)(PastaDetails);