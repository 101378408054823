import React from 'react'
import PastasList from './PastasList'
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import Loader from './Loader'
import {Helmet} from "react-helmet";

class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;

        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('usersPastas') + this.props.match.params.userId)
            .then(function (response) {
                self.setState({
                    pastas: response.data,
                    launchLoader: false
                });
            });
    }

    render() {

        let metaTags = (
            <Helmet>
                <title>Pasty użytkownika - Copypasta.pl</title>
                <meta name="description" content='Pasty wrzucone przez użytkownika serwisu Copypasta.pl'/>
                <meta name="keywords" content='pasty użytkownik Copypasta.pl '/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Pasty użytkownika - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description" content='Pasty wrzucone przez użytkownika serwisu Copypasta.pl'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='Pasty użytkownika - Copypasta.pl'/>
                <meta name="twitter:description" content='Pasty wrzucone przez użytkownika serwisu Copypasta.pl'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    <PastasList
                        canAddToFav={true}
                        canRemoveFromFav={false}
                        canHide={false}
                        canDelete={false}
                        canEdit={false}
                        listOfPastas={this.state.pastas}/>
                </div>
            </div>

        )
    }
}

export default User;