import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ApiEndpoints from "./ApiEndpoints";
import axios from 'axios'
import {Link} from 'react-router-dom'
import Utils from './Utils';
import IncludeIcon from './IncludeIcon'
import {Login} from 'react-facebook';
import {Button, Dropdown, Icon, Menu} from 'antd';


const PastaLogin = ({popupContainer, onItemClick}) => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const restoreState = useCallback(() => {
        dispatch({type: 'STATE_RESTORE'});
    }, [dispatch]);

    const setUsr = useCallback((usr) => {
        let credentials = {
            user_ID: usr.id,
            api_token: usr.authToken
        };
        dispatch({type: 'USER_FB_TOKEN_RECEIVED', payload: credentials});
        let qstr = Utils.serializeToUri(credentials);
        axios.get(ApiEndpoints.getUrl('currentUserInfo') + qstr).then((response) => {
            dispatch({
                type: "USER_LOGGED_IN", payload: response.data
            });
        });
    }, [dispatch]);

    const onFacebookResponse = useCallback((response) => {
        const {tokenDetail} = response;
        const {userID, accessToken} = tokenDetail;
        let usr = {};
        usr.id = userID;
        usr.authToken = accessToken;
        setUsr(usr);
    }, [setUsr]);

    const logout = useCallback(() => {
        localStorage.removeItem('state');
    }, []);

    useEffect(() => {
        restoreState();
    }, [restoreState]);

    const onMenuClick = useCallback(({key}) => {

        if (key === 'logout') {
            logout();
        }

        onItemClick()

    }, [logout, onItemClick]);

    let usrinfo = null;
    let avatar = null;
    let userDropdown = null;
    if (typeof user !== 'undefined') {
        if (user.has_avatar !== 0) {

            avatar = <img className="imgUser"
                          src={ApiEndpoints.apiOrigin + ApiEndpoints.getUserPhoto + user.id + '.jpg'}
                          alt="avatar"/>;
            usrinfo =
                <Menu onClick={onMenuClick}>
                    <Menu.Item><Link to="/user"><span className='icon icon-user'/> Konto</Link></Menu.Item>
                    <Menu.Item><Link to='/user/pastas'><span className="icon icon-circleupload"/> Wrzucone
                        pasty</Link></Menu.Item>
                    <Menu.Item><Link to='/user/recent'><span className="icon icon-lightningalt"/> Ostatnio
                        przeglądane</Link></Menu.Item>
                    <Menu.Item><Link to='/user/favourite'><span className="icon icon-star"/> Ulubione</Link></Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item key='logout'>
                        <IncludeIcon iconBase='icon'
                                     iconDetails='icon-exit'/> Wyloguj
                    </Menu.Item>
                </Menu>;
            userDropdown = <Dropdown overlay={usrinfo} overlayClassName="navbar-dropdown"
                                     getPopupContainer={() => popupContainer}
                                     trigger={['click']}><span>{avatar}<span>{user.name} <Icon
                type="down"/></span></span></Dropdown>;
        }
    }
    let fbBtn = null;
    if (typeof user === 'undefined') {
        fbBtn = (
            <Login scope="public_profile" className='btn facebook_login' onCompleted={onFacebookResponse}>
                {({loading, handleClick, error, data}) => (
                    <Button loading={loading} disabled={loading} onClick={handleClick}
                            className="btn facebook_login"><span
                        className="icon icon-facebook"/> Zaloguj przez FB</Button>
                )}
            </Login>
        );
    }
    return (
        <span className="Login">
            {userDropdown}
            {fbBtn}
        </span>
    )

};

export default PastaLogin;