import React from 'react'
import ApiEndpoints from './ApiEndpoints'
import Text from 'react-format-text'
import PropTypes from 'prop-types'

class CoverPhoto extends React.Component {
    render() {

        let text = null;
        let coverPhoto = null;

        if (this.props.shortContent !== '') {

            let textShort = this.props.shortContent;
            textShort = textShort.replace(/\r/g, '');

            text = textShort + '...';
        }

        if (this.props.has_cover !== 0) {

            let divImageStyle = {
                backgroundImage: 'url("' + ApiEndpoints.apiOrigin + ApiEndpoints.pastaCoverPhoto + this.props.id + '.jpg")'
            };

            if (this.props.isMoreButtonClicked) {
                coverPhoto =
                    <div className="fullWidth imagePasta ">
                        <img src={ApiEndpoints.apiOrigin + ApiEndpoints.pastaCoverPhoto + this.props.id + '.jpg'}
                             alt="cover"/>
                    </div>;
            } else {
                coverPhoto =
                    <div className="maxWidth fullWidth imagePasta display_block">
                        <div className="fullWidth imageDiv" style={divImageStyle}>
                            <div className="maxWidth fullWidth alignVerticalMiddle imgBackdrop">
                                <div id="textImgCover">{text}<br/>{this.props.children}</div>
                            </div>
                        </div>
                    </div>;
            }
        } else {
            if (this.props.isMoreButtonClicked) {
                coverPhoto = null;
            } else {
                coverPhoto = <div className="pasta_content">
                    <div><Text>{text}</Text><br/>{this.props.children}</div>
                </div>;
            }
        }

        return (
            <span>
                {coverPhoto}
            </span>
        )
    }
}

CoverPhoto.defaultProps = {
    isMoreButtonClicked: false,
    shortContent: '',
    has_cover: 0
};

CoverPhoto.propTypes = {
    has_cover: PropTypes.number.isRequired
};

export default CoverPhoto;