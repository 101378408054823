import React from 'react'
import Logo from './content/img/logoxD.svg'
import {connect} from "react-redux";
import axios from 'axios'
import {Link} from 'react-router-dom'
import './animate.css'
import IncludeIcon from './IncludeIcon'
import {slide as Menu} from 'react-burger-menu'
import ApiEndpoints from './ApiEndpoints'
import CustomNavLink from "./CustomNavLink";
import Login from "./Login";
import {array, func} from 'prop-types';
import {Input} from 'antd';
import Utils from './Utils';

class NavbarOwn extends React.Component {

    constructor(props) {
        super(props);

        this.removeSearch = this.removeSearch.bind(this);
        this.searchForPasta = this.searchForPasta.bind(this);
        this.showSearch = this.showSearch.bind(this);


        this.state = {
            selected: null,
            listItems: [],
            form: {
                text: '',
                content: ''
            },
            isDisplayed: false,
            scrollStatus: "scroll_enabled",
            showSearch: false,
            toHide: '',
            windowWidth: window.innerWidth,
            isMenuOpen: false
        }
        ;
    }

    onSelectItem(Key) {
        this.setState({selected: Key});
    }

    searchForPasta(event) {
        let formN = this.state.form;
        formN.text = event.target.value;

        this.setState({
            form: formN
        });

        if (this.state.form.text !== '') {
            if (this.state.form.text.length >= 5) {
                this.setState({
                    showSearch: true
                });
            } else {
                this.setState({
                    showSearch: false
                });
            }

            axios.get(ApiEndpoints.getUrl("searchTitle") + this.state.form.text)
                .then((response) => {
                        this.setState({
                            listItems: response.data,
                            scrollStatus: "scroll_disabled",
                            toHide: 'hidden'
                        });
                    }
                );
        } else {
            this.setState({
                listItems: [],
                scrollStatus: 'scroll_enabled',
                toHide: 'hidden',
                showSearch: false
            });
        }
    }

    showSearch() {
        this.setState({isDisplayed: !this.state.isDisplayed});
    }

    removeSearch(event, redirected) {

        event.preventDefault();

        if (this.state.isDisplayed) {

            this.setState({
                listItems: [],
                scrollStatus: "scroll_enabled",
                isDisplayed: false,
                toHide: 'hidden',
                showSearch: false
            });
        } else {
            this.setState({
                listItems: [],
                scrollStatus: "scroll_enabled",
                toHide: 'hidden',
                showSearch: false
            });
        }

        if (redirected !== this.props.redirected_to_pasta) {
            this.props.setRedirect(redirected);
        }

    }

    componentDidMount() {

        this.setState({
            listItems: [],
            scrollStatus: "scroll_enabled",
            toHide: '',
            showSearch: false,
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.redirected_to_pasta) {
            this.setState({
                listItems: [],
                scrollStatus: "scroll_enabled",
                toHide: 'hidden',
                showSearch: false,
            });
        }


    }

    onMenuStateChange = (state) => {
        this.setState({
            isMenuOpen: state.isOpen
        })
    };

    closeMenu = () => {
        this.setState({
            isMenuOpen: false
        });
    };

    render() {

        let searchResults = null;
        let advancedSearch = null;


        if (this.state.listItems.length !== 0 || this.state.showSearch) {

            if (this.state.form.text.length >= 5) {

                advancedSearch = (
                    <div className="link_underline searchResult searchColor"
                         onClick={(event) => this.removeSearch(event, false)}>
                        <Link to={'/search/' + Utils.encodePercentChar(this.state.form.text)}>
                            <IncludeIcon iconBase="glyphicon" iconDetails="icon-search" style={{border: 'none'}}/> <strong>Rozszerzone
                            wyszukiwanie "{this.state.form.text}"</strong>
                        </Link>
                    </div>
                );
            }

            searchResults =
                <div className="fixedTop fullWidth" id="searchContainer">
                    <div className="backdrop" onClick={(event) => this.removeSearch(event, false)}/>
                    <div className="container boxCentered bgLightGrey searchResults">
                        <div className="searchBox">
                            {this.state.listItems.map(item => <div key={item.id}
                                                                   onClick={(event) => this.removeSearch(event, true)}
                                                                   className="searchResult">
                                    <Link to={ApiEndpoints.pastaInfo + item.id}>{Utils.encodePercentChar(item.title)}</Link>
                                </div>
                            )}
                            {advancedSearch}
                        </div>
                    </div>
                </div>;

        } else {
            searchResults = null;
        }

        if (this.state.windowWidth <= 768 && !this.state.isDisplayed) {
            searchResults = null;
        }

        let searchMobile = null;

        if (this.state.isDisplayed) {
            searchMobile = <div className="fullWidth display_block search-view-mobile">
                <Input className="input-sm alwaysOnTop searchInput" value={this.state.form.text}
                       onClick={this.searchForPasta}
                       onChange={this.searchForPasta} placeholder="Wyszukaj pastę"/>
                <div className={this.state.toHide + " transparent fullScreen absolute"}
                     onClick={(event) => this.removeSearch(event, false)}/>
            </div>
        }

        const {isMenuOpen} = this.state;

        return (
            <div className={`${this.state.scrollStatus} no_padding`}>
                <Menu pageWrapId='page-wrap' outerContainerId='outer-container' className='hamburger-menu' right
                      id='menu-hamburger' onStateChange={this.onMenuStateChange} isOpen={isMenuOpen}>
                    <NavLinks tabs={this.props.tabs} isLoggedIn={this.props.isLoggedIn} onItemClick={this.closeMenu}
                              popupContainer={document.getElementById('menu-hamburger')}/>
                </Menu>
                <nav className="no_padding nav-main" id="navbar-nav">
                    <div className='navbar-header' onClick={(event) => this.removeSearch(event, false)}>
                        <div className="navbar-brand">
                            <Link to="/" className="brand"><img src={Logo} alt="Logo"/></Link>
                        </div>
                        <button onClick={this.showSearch} className="btn searchBtn disableOnDesktop"><span
                            className="icon icon-search"/>
                        </button>
                        <div className="search disableOnMobile">
                            <input className="input-sm" value={this.state.form.text} onClick={this.searchForPasta}
                                   onChange={this.searchForPasta} placeholder="Wyszukaj pastę"/>
                        </div>

                        <div className='disable-on-mobile nav-links'>
                            <NavLinks tabs={this.props.tabs} isLoggedIn={this.props.isLoggedIn}
                                      popupContainer={document.getElementById('navbar-nav')}/>
                        </div>

                    </div>
                </nav>
                {searchMobile}
                {searchResults}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: (typeof state.credentials !== 'undefined'),
        redirected_to_pasta: state.redirected_to_pasta,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRedirect: (value) => dispatch({type: 'REDIRECT_PASTA', payload: value}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarOwn)

const NavLinks = ({tabs, isLoggedIn, popupContainer, onItemClick}) => {

    return (
        <div className='nev-links-container'>
            {tabs
                .filter((i) => i.auth === false || i.auth === isLoggedIn)
                .map((obj, i) => <CustomNavLink key={i} hasIcon={obj.hasIcon} objIcon={obj.icon}
                                                objName={obj.name}
                                                onItemClick={onItemClick}
                                                to={obj.route}>{obj.name}</CustomNavLink>)}

            <Login popupContainer={popupContainer} onItemClick={onItemClick}/>
        </div>
    )

};

NavLinks.propTypes = {
    tabs: array.isRequired,
    onItemClick: func
};

NavLinks.defaultProps = {
    popupContainer: document.body,
    onItemClick: () => {
    }
};