import React from 'react'
import {connect} from 'react-redux'
import axios from 'axios'
import PastasList from './PastasList'
import ApiEndpoints from './ApiEndpoints'
import Utils from './Utils';
import Loader from './Loader';
import {Helmet} from "react-helmet";

class RecentPastas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;
        let qstr = Utils.serializeToUri(this.props.credentials);
        this.setState({
            launchLoader: true
        });

        axios.get(ApiEndpoints.getUrl('pastasRecent') + qstr)
            .then((response) => {
                self.setState({
                    pastas: response.data,
                    launchLoader: false
                });
            });
    }

    render() {

        let metaTags = (
            <Helmet>
                <title>Ostatnio przedlądane pasty - Copypasta.pl</title>
                <meta name="description" content='Ostatnio przegladane pasty przez użytkownika serwisu Copypasta'/>
                <meta name="keywords" content='pasta użytkownik Copypasta.pl'/>
                <meta name="author" content=''/>
                <meta name="copyright" content=""/>
                <meta name="application-name" content="Copypasta"/>

                <meta property="og:title" content='Ostatnio przedlądane pasty - Copypasta.pl'/>
                <meta property="og:type" content="article"/>
                <meta property="og:image" content=''/>
                <meta property="og:url" content='www.copypasta.pl'/>
                <meta property="og:description"
                      content='Ostatnio przegladane pasty przez użytkownika serwisu Copypasta'/>

                {/*for Twitter*/}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content='Ostatnio przedlądane pasty - Copypasta.pl'/>
                <meta name="twitter:description"
                      content='Ostatnio przegladane pasty przez użytkownika serwisu Copypasta'/>
                <meta name="twitter:image" content=''/>
                <meta name="fb:ap_id" content={ApiEndpoints.fbAppId}/>
            </Helmet>
        );

        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader}/>
                {metaTags}
                <div className="marginTop">
                    <PastasList
                        canAddToFav={true}
                        canRemoveFromFav={false}
                        canHide={false}
                        canDelete={false}
                        canEdit={false}
                        listOfPastas={this.state.pastas}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        credentials: state.credentials
    };
};
export default connect(mapStateToProps)(RecentPastas);