import React from 'react'
import PastasList from './PastasList'
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import Utils from './Utils';
import {connect} from "react-redux";
import Loader from './Loader'

 class UserPastas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pastas: [],
            launchLoader: false
        };
    }

    componentDidMount() {
        let self = this;

        this.setState({
            launchLoader: true
        });

        let qstr = Utils.serializeToUri(this.props.credentials);
        axios.get(ApiEndpoints.getUrl('userPastas') + qstr)
            .then(function (response) {
                self.setState({
                    pastas: response.data,
                    launchLoader: false
                });
            });
    }

    render() {
        return (
            <div className="container">
                <Loader launchLoader={this.state.launchLoader} />
                <div className="marginTop">
                    <PastasList
                        canAddToFav={false}
                        canRemoveFromFav={false}
                        canHide={false}
                        canDelete={true}
                        canEdit={true}
                        listOfPastas={this.state.pastas}/>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        user: state.user
    };
};
export default connect(mapStateToProps)(UserPastas);