import React from 'react'
import Pasta from './Pasta'
import Utils from './Utils'
import {connect} from "react-redux";
import axios from 'axios'
import ApiEndpoints from './ApiEndpoints'
import Alert from './Alert'
import Loader from './Loader'

class PastasList extends React.Component {

    constructor(props) {
        super(props);

        this.state =  {
            launchLoader: false,
            isAlertTriggered: false,
            alertClass: '',
            alertText: '',
            pastasList: []
        };
    }

    delete_pasta(pasta) {
        let self = pasta;
        let obj = self.props.credentials;
        let qstr = Utils.serializeToUri(obj);

        this.setState({
            launchLoader: true
        });

        axios({
            url: ApiEndpoints.getUrl('removePasta') + pasta.props.ids + qstr,
            method: 'delete'
        })
            .then(() => {

                let pastasArrayPos = -1;

                for(let i = 0; i < this.state.pastasList.length; i++)
                {
                    if(this.state.pastasList[i].id === pasta.props.ids)
                    {
                        pastasArrayPos = i;
                        break;
                    }
                }

                if(pastasArrayPos > -1)
                {
                    this.state.pastasList.splice(pastasArrayPos, 1);
                }


                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie usunięto pastę',
                    launchLoader: false,
                    listOfPastas: this.state.pastasList

                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się usunąć pasty',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    }

    removFav(pasta) {
        let self = pasta;
        let obj = self.props.credentials;
        let qstr = Utils.serializeToUri(obj);

        this.setState({
            launchLoader: true
        });

        axios({
            url: ApiEndpoints.getUrl('pastaFavourite') + pasta.props.ids + qstr,
            method: 'delete'
        })
            .then(() => {

                let pastasArrayPos = -1;

                for(let i = 0; i < this.state.pastasList.length; i++)
                {
                    if(this.state.pastasList[i].id === pasta.props.ids)
                    {
                        pastasArrayPos = i;
                        break;
                    }
                }

                if(pastasArrayPos > -1)
                {
                    this.state.pastasList.splice(pastasArrayPos, 1);
                }

                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie usunięto pastę z ulubionych',
                    launchLoader: false,
                    listOfPastas: this.state.pastasList
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się usunąć pasty z ulubionych',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });

    }

    hidePasta(pasta)
    {
        let self = pasta;
        let obj = self.props.credentials;
        let qstr = Utils.serializeToUri(obj);

        this.setState({launchLoader: true});

        axios({
            url: ApiEndpoints.getUrl('hidePasta') + pasta.props.ids + qstr,
            method: 'get'
        })
            .then(() => {

                let pastasArrayPos = -1;

                for(let i = 0; i < this.state.pastasList.length; i++)
                {
                    if(this.state.pastasList[i].id === pasta.props.ids)
                    {
                        pastasArrayPos = i;
                        break;
                    }
                }

                if(pastasArrayPos > -1)
                {
                    this.state.pastasList.splice(pastasArrayPos, 1);
                }

                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: 'Pomyślnie ukryto pastę',
                    launchLoader: false,
                    listOfPastas: this.state.pastasList
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_danger',
                    alertText: 'Nie udało się ukryć pasty',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            });
    }

    confirmPasta(pasta) {
        let self = pasta;
        let obj = self.props.credentials;
        let qstr = Utils.serializeToUri(obj);

        this.setState({launchLoader: true});

        axios.get(ApiEndpoints.getUrl('confirmPasta') + pasta.props.ids + qstr)
            .then((response) => {
                let pastasArrayPos = -1;

                for(let i = 0; i < this.state.pastasList.length; i++)
                {
                    if(this.state.pastasList[i].id === pasta.props.ids)
                    {
                        pastasArrayPos = i;
                        break;
                    }
                }

                if(pastasArrayPos > -1)
                {
                    this.state.pastasList.splice(pastasArrayPos, 1);
                }

                this.setState({
                    launchLoader: false,
                    isAlertTriggered: true,
                    alertClass: 'alert_success',
                    alertText: response.data,
                    listOfPastas: this.state.pastasList
                });
                setTimeout(function () {
                    self.setState({isAlertTriggered: false});
                }, 4000)
            })
            .catch(() => {
                this.setState({
                    isAlertTriggered: true,
                    alertClass: 'alert_warning',
                    alertText: 'Nie udało się zatwierdzić',
                    launchLoader: false
                });
                setTimeout(function () {
                    self.setState({
                        isAlertTriggered: false
                    });
                }, 4000);
            });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pastasList: nextProps.listOfPastas
        });
    }

    componentDidMount() {
        this.setState({
            pastasList: this.props.listOfPastas
        });
    }

    render() {

        let alertCustom = null;

        if (this.state.isAlertTriggered) {

            alertCustom = <Alert alertText={this.state.alertText} alertClass={this.state.alertClass}
                                 isAnimated={true} animationNameToOpen='fadeInDown' animationNameToFade='fadeOutUp'
                                 animationTime={2000}/>
        }

        let emptyPastas = null;

        if(!this.state.pastasList || this.state.pastasList.length === 0)
        {
            emptyPastas = <div className="absolute empty">
                <span className="icon-restaurantmenu"/>
            </div>;
        }

        return (
            <div>
                {alertCustom}
                <Loader launchLoader={this.state.launchLoader} />
                {emptyPastas}
                {this.state.pastasList && this.state.pastasList.map(SPasta => <Pasta key={SPasta.id} shortContent={SPasta.short_content}
                                                              title={SPasta.title} author={SPasta.author}
                                                              picRrel={SPasta.picRrel} cover={SPasta.cover}
                                                              author_id={SPasta.author_id}
                                                              tags={SPasta.tags} ids={SPasta.id}
                                                              views={SPasta.views} votes={SPasta.votes}
                                                              confirmed={SPasta.confirmed}
                                                              isMoreButtonClicked={this.props.isMoreButtonClicked}
                                                              role={SPasta.role}
                                                              author_photo={SPasta.author_photo}
                                                              canDelete={this.props.canDelete}
                                                              canEdit={this.props.canEdit}
                                                              canHide={this.props.canHide}
                                                              canAddToFav={this.props.canAddToFav}
                                                              canRemoveFromFav={this.props.canRemoveFromFav}
                                                              has_cover={SPasta.has_cover}
                                                              has_picrel={SPasta.has_picrel}
                                                              credits={SPasta.credits}
                                                              has_avatar={SPasta.has_avatar}
                                                              delete_pasta={this.delete_pasta.bind(this)}
                                                              removeFavourites={this.removFav.bind(this)}
                                                              confirm_pasta={this.confirmPasta.bind(this)}
                                                              hide_pasta={this.hidePasta.bind(this)}
                                                              content={SPasta.content} created_at={SPasta.created_at} />
                )}
            </div>
        )
    }
}

PastasList.defaultProps = {
    isMoreButtonClicked: false,
    canAddToFav: false,
    canDelete: false,
    canEdit: false,
    canHide: false
};

const mapStateToProps = (state) => {
    return {
        credentials: state.credentials,
        user: state.user
    };
};

export default connect(mapStateToProps)(PastasList);