import React from 'react'

export default class Alert extends React.Component {


    constructor(props) {
        super(props);
        this.state = Object.assign({}, props,
            {
                animation: '',
                animationStatus: 'open'
            });
    }

    componentWillMount() {
        if(this.state.isAnimated)
        {
            this.setState({animation: 'animated ' + this.state.animationNameToOpen});
        }
    }

    componentDidMount() {
        if(this.state.isAnimated)
        {
            setTimeout(this.triggerHidingAlert.bind(this), this.props.animationTime);
        }
    }

    triggerHidingAlert() {
        this.setState({animation: 'animated ' + this.state.animationNameToFade});
        setTimeout(this.hideAlert.bind(this), 2000);
    }

    hideAlert() {
        this.setState({animationStatus: 'close'});
    }

    render() {

        let isAnimated = null;

        if(this.state.animationStatus === 'open')
        {
            isAnimated = (
                <div onClick={this.triggerHidingAlert.bind(this)} className={this.state.alertClass + ' alert_custom ' + this.state.animation}>
                    <strong>{this.state.alertText}</strong>
                </div>);
        }

        return (
            <div>
                {isAnimated}
            </div>
        )
    }
}